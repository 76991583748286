import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import TextInput from "../../ui/TextInput";

import {CUSTOMER_BACK, resetAction} from "../../../store/actions/Customer";
import toast from "react-hot-toast";

const Reset = (props) => {

    // console.log('Reset')

    const dispatch = useDispatch();

    const user = useSelector(state => state.Customer.user);
    const submissionId = useSelector(state => state.App.submissionId);
    const token = useSelector(state => state.Customer.token);


    const [currentPassword, updateCurrentPassword] = useState(null);
    const passwordChangeHandler = useCallback(async (inputIdentifier, inputValue) => {
            if (inputValue !== currentPassword) {
                updateCurrentPassword(inputValue);
            }
        },
        [currentPassword]
    );
    const [currentPasswordRepeat, updateCurrentPasswordRepeat] = useState(null);
    const passwordRepeatChangeHandler = useCallback(async (inputIdentifier, inputValue) => {
            if (inputValue !== currentPasswordRepeat) {
                updateCurrentPasswordRepeat(inputValue);
            }
        },
        [currentPasswordRepeat]
    );

    function backHandler() {
        dispatch({
            type: CUSTOMER_BACK,
        });
    }

    function handleSubmit(event) {

        event.preventDefault();

        if(currentPassword !== currentPasswordRepeat) {
            toast.error('Passwörter stimmen nicht überein');
        } else {
            const request = {
                submissionId: submissionId,
                token: token,
                password: currentPassword,
                repeat_password: currentPasswordRepeat,
            };

            dispatch(resetAction(request));
        }


    }

    if(!user.id && token) {
        return (
            <div>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <TextInput
                        id="password"
                        type="password"
                        label="Passwort"
                        required={1}
                        minLength={7}
                        maxLength={127}
                        onInputChange={passwordChangeHandler}
                        initialValue={currentPassword}
                    />

                    <TextInput
                        id="repeat_password"
                        type="password"
                        label="Passwort wiederholen"
                        required={1}
                        minLength={7}
                        maxLength={127}
                        onInputChange={passwordRepeatChangeHandler}
                        initialValue={currentPasswordRepeat}
                    />

                    <div className="button-container">
                        <button type="submit">Senden</button>
                    </div>

                </form>
                <button onClick={backHandler}>Zurück</button>
            </div>
        );
    }

}

export default Reset