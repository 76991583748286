import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';

import ProfileDownloadCode from "./ProfileDownloadCode";
import ProfileDownloadCustomer from "./ProfileDownloadCustomer";
import {CUSTOMER_BACK} from "../../../store/actions/Customer";
import {useDispatch} from "react-redux";
import Button from '../../ui/Button';
import { faArrowLeft as fatArrowLeft } from '@fortawesome/pro-thin-svg-icons';

const ProfileDownload = (props) => {

    // console.log('ProfileDownload')
    const dispatch = useDispatch();

    function backHandler() {
        dispatch({
            type: CUSTOMER_BACK,
        });
    }

    return (
        <div id={'modulewrapper'}>
            <Button primary icon={fatArrowLeft} iconPosition="left" onClick={backHandler}>Zurück</Button>
            <Tabs>
                <TabList>
                    <Tab>Meine Dateien</Tab>
                    <Tab>Download Code</Tab>
                </TabList>

                <TabPanel>
                    <ProfileDownloadCustomer
                        module={module}
                    />
                </TabPanel>
                <TabPanel>
                    <ProfileDownloadCode
                        module={module}
                    />
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default ProfileDownload