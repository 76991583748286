import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import TextInput from "../../ui/TextInput";

import {CUSTOMER_BACK, forgetAction} from "../../../store/actions/Customer";
import {
    faArrowLeft as fatArrowLeft
} from '@fortawesome/pro-thin-svg-icons';
import Button from '../../ui/Button';
import NextButton from '../../ui/NextButton';

const Forget = (props) => {

    // console.log('Forget')

    const dispatch = useDispatch();

    const user = useSelector(state => state.Customer.user);
    const submissionId = useSelector(state => state.App.submissionId);

    const [currentUsername, updateCurrentUsername] = useState(null);
    const usernameChangeHandler = useCallback(async (inputIdentifier, inputValue) => {
            if (inputValue !== currentUsername) {
                updateCurrentUsername(inputValue);
                // await dispatch(sensorActions.updateSensorValue(authKey, 'customer_name', itemId, inputValue));
            }
        },
        [currentUsername]
    );

    function backHandler() {
        dispatch({
            type: CUSTOMER_BACK,
        });
    }

    function handleSubmit(event) {

        event.preventDefault();

        const request = {
            submissionId: submissionId,
            username: currentUsername,
        };

        dispatch(forgetAction(request));
    }

    if(!user.id) {
        return (
            <div>
                <form className="fields-container" onSubmit={handleSubmit} autoComplete="off">
                    <Button primary icon={fatArrowLeft} iconPosition="left" onClick={backHandler}>Zurück</Button>
                    <TextInput
                        id="username"
                        type="text"
                        label="E-Mail"
                        required={1}
                        minLength={7}
                        maxLength={127}
                        onInputChange={usernameChangeHandler}
                        initialValue={currentUsername}
                    />
                    <NextButton type="submit">Senden</NextButton>
                </form>
            </div>
        );
    }

}

export default Forget