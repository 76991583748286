
const Note = props => {

    return (
        <div>
            <h4>{props.label}</h4>
            {
                props.description &&
                <div dangerouslySetInnerHTML={{ __html: props.description }} />
            }
        </div>
    );
};

export default Note;
