import React from "react";

import Branch from "../module/branch/Branch";
import Customer from "../module/customer/Customer";
import Appointment from "../module/appointment/Appointment";
import Seminar from "../module/seminar/Seminar";
import Download from "../module/download/Download";
import Payment from "../module/payment/Payment";
import ProfileDownload from "../module/download/ProfileDownload";
import Profile from "../module/customer/Profile";
import Login from "../module/customer/Login";
import Forget from "../module/customer/Forget";
import Reset from "../module/customer/Reset";
import Datainput from "../module/datainput/Datainput";
import Email from "../module/email/Email";
import Freetext from "../module/freetext/Freetext";
import Checklist from "../module/checklist/Checklist";
import Evaluation from "../module/evaluation/Evaluation";
import Success from "../module/Success";

const Components = {
    branch: Branch,
    customer: Customer,
    appointment: Appointment,
    seminar: Seminar,
    payment: Payment,
    download: Download,
    profileDownload: ProfileDownload,
    datainput: Datainput,
    email: Email,
    freetext: Freetext,
    checklist: Checklist,
    evaluation: Evaluation,
    success: Success,
    profile: Profile,
    login: Login,
    forget: Forget,
    reset: Reset,
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default module => {
    if (typeof Components[module] !== "undefined") {
        return React.createElement(Components[module], {});
    }

    // component doesn't exist yet
    return React.createElement(
        () => <div>The component {module} has not been created yet.</div>,
        { key: module }
    );
}