import React, {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {PayPalScriptProvider, PayPalButtons} from "@paypal/react-paypal-js";

import {offlinePaymentAction, onlinePaymentAction} from "../../../store/actions/Payment";
import TermsInput from "../../ui/TermsInput";
import toast from "react-hot-toast";
import { faArrowRight as fatArrowRight } from '@fortawesome/pro-thin-svg-icons';
import Button from '../../ui/Button';
import Table from '../../ui/Table';
import InfoBlock from '../../ui/InfoBlock';

const Payment = () => {
    // console.log('Payment')

    const dispatch = useDispatch();
    const submissionId = useSelector(state => state.App.submissionId);
    const module = useSelector(state => state.App.module);
    const moduleId = useSelector(state => state.App.moduleId);

    const [totalCross, updateTotalCross] = useState(module.data.totals.total_gross);
    const [termsChecked, updateTermsChecked] = useState(false);
    const [submitted, updateSubmitted] = useState(false);

    const [paymentExists, updatePaymentExists] = useState(false);

    useEffect(() => {
        updateTotalCross(module.data.totals.total_gross);
    }, [moduleId, module]);


    const paypalHandler = useCallback(async (data, actions) => {
        return actions.order.capture().then(
            (details) => {
                updateSubmitted(true);
                if ((module.data.module.display_terms_conditions && module.data.module.terms_conditions)) {

                    if (document.querySelector('#module-terms-condition-checkbox').checked) {
                        dispatch(onlinePaymentAction(submissionId, moduleId, 'paypal', details, data, {termsConditions: 1}, errorFunction));
                    } else {
                        toast.error('Nicht alle erforderlichen Felder ausgefüllt.');
                    }

                } else {
                    dispatch(onlinePaymentAction(submissionId, moduleId, 'paypal', details, data, errorFunction));
                }
            });

    },[moduleId, submissionId, module]);

    function handleInvoice() {
        updateSubmitted(true);
        if ((module.data.module.display_terms_conditions && module.data.module.terms_conditions)) {

            if (document.querySelector('#module-terms-condition-checkbox').checked) {
                dispatch(offlinePaymentAction(submissionId, module.data.module.id, 'invoice', {termsConditions: 1}, errorFunction));
            } else {
                toast.error('Nicht alle erforderlichen Felder ausgefüllt.');
            }

        } else {
            dispatch(offlinePaymentAction(submissionId, module.data.module.id, 'invoice', errorFunction));
        }
    }

    function errorFunction(response) {
        if (response.proceeded === 1) {
            updatePaymentExists(false);
            toast.error('Fehler - Zahlung bereits verarbeitet.')
        }
    }

    const termsChanged = function (result) {
        updateTermsChecked(result);
    };

    if(paymentExists) {
        return (
            <div className="payment">
                <div>Fehler</div>
            </div>
        );
    }

    return (
        <div className="payment">
            <Table className="payment-table">
                <Table.Head>
                    <Table.Row>
                        <Table.Cell>#</Table.Cell>
                        <Table.Cell>Bezeichnung</Table.Cell>
                        <Table.Cell>Menge</Table.Cell>
                        <Table.Cell>Einzelpreis</Table.Cell>
                        <Table.Cell>Gesamtpreis</Table.Cell>
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {module.data.positions.map(
                        (position, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>{(index + 1)}</Table.Cell>
                                    <Table.Cell>{}
                                        <div
                                            dangerouslySetInnerHTML={{__html: position.title}}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>{position.qty}</Table.Cell>
                                    <Table.Cell>{(position.unit_price).toLocaleString('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    })}</Table.Cell>
                                    <Table.Cell>{(position.line_total_net).toLocaleString('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    })}</Table.Cell>
                                </Table.Row>
                            );
                        }
                    )}
                </Table.Body>
                <Table.Foot>
                    <Table.Row>
                        <Table.Cell colSpan={4}>Nettobetrag:</Table.Cell>
                        <Table.Cell>{(module.data.totals.total_net).toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR'
                        })}</Table.Cell>
                    </Table.Row>

                    {Object.values(module.data.totals.subTotalTax).map((tax, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell colSpan={4}>{tax.title} ({tax.rate}%):</Table.Cell>
                                    <Table.Cell>{(tax.value).toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})}</Table.Cell>
                                </Table.Row>
                            );
                        }
                    )}
                    { module.data.totals.subTotalTax.length > 1 &&
                        <Table.Row>
                            <Table.Cell colSpan={4}>Gesamtsteuer:</Table.Cell>
                            <Table.Cell>{(module.data.totals.total_tax).toLocaleString('de-DE', {
                                style: 'currency',
                                currency: 'EUR'
                            })}</Table.Cell>
                        </Table.Row>
                    }

                    <Table.Row>
                        <Table.Cell colSpan={4}>Bruttobetrag:</Table.Cell>
                        <Table.Cell>{(module.data.totals.total_gross).toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR'
                        })}</Table.Cell>
                    </Table.Row>
                </Table.Foot>
            </Table>
            {
                module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 1 &&
                <TermsInput
                    text={module.data.module.terms_conditions}
                    submitted={submitted}
                    changeHandler={termsChanged}
                />
            }
            {
                (typeof module.data.exists === 'undefined') ?
                    <div>
                        {
                            (typeof module.data.exists === 'undefined') && (
                                (module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 1 && termsChecked) ||
                                module.data.module.display_terms_conditions === 0
                            )
                                ?
                                <div id={'paymentWrapper'}>
                                    {
                                        typeof module.data.methods.paypal !== 'undefined' &&
                                        <div className="paypal">
                                            <InfoBlock
                                                headingLevel="2"
                                                heading={module.data.methods.paypal.title}
                                                description={module.data.methods.paypal.description}
                                            />
                                            {
                                                !submitted &&
                                                <PayPalScriptProvider
                                                    options={{
                                                        'client-id': typeof module.data.methods_config['client-id'] !== 'undefined' ? module.data.methods_config['client-id'] : '',
                                                        'disable-funding': typeof module.data.methods_config['disable-funding'] !== 'undefined' ? module.data.methods_config['disable-funding'] : '',
                                                        'currency': typeof module.data.methods_config['currency'] !== 'undefined' ? module.data.methods_config['currency'] : '',
                                                    }}
                                                >
                                                    <PayPalButtons
                                                        style={{layout: "horizontal"}}
                                                        forceReRender={[totalCross]}
                                                        createOrder={(data, actions) => {
                                                            return actions.order.create({
                                                                purchase_units: [
                                                                    {
                                                                        amount: {
                                                                            value: parseFloat(totalCross).toFixed(2),
                                                                        },
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                        // createOrder={paypalInitHandler}
                                                        onApprove={(data, actions) => {
                                                            return paypalHandler(data, actions);
                                                            // return actions.order.capture().then(
                                                            //     (details) => {
                                                            //     // handlePaypal(details, data);
                                                            //     paypalHandler(details, data);
                                                            // });
                                                        }}
                                                    />
                                                </PayPalScriptProvider>
                                            }
                                        </div>
                                    }
                                    {
                                        typeof module.data.methods.invoice !== 'undefined' &&
                                        <div className="fields-container">
                                            <InfoBlock
                                                headingLevel="2"
                                                heading={module.data.methods.invoice.title}
                                                description={module.data.methods.invoice.description}
                                            />
                                            {
                                                !submitted &&
                                                <Button primary icon={fatArrowRight} onClick={handleInvoice}>
                                                    Bezahlen per Rechnung
                                                </Button>
                                            }
                                        </div>
                                    }
                                </div>
                                :
                                <div id={'paymentWrapper'}>Füllen Sie die Pflichtfelder aus um fortzufahren.</div>
                        }
                    </div>
                    :
                    <div>Fehler bei der Datenverarbeitung. Zahlung bereits vorhanden.</div>
            }
        </div>
    );
}

export default Payment;