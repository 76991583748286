/**
 * StyledInput a wrapper for NativeBase floatingLabel Input
 * https://docs.nativebase.io/Components.html#floating-label-headref
 *
 * @typedef {Object<string, any>} Props
 * @property {string} iconName name of icon
 * @property {string} iconSet Iconset from react-native-vector-icons
 *
 * @returns {*}
 */

import React, {useReducer, useEffect} from 'react';

import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

const INPUT_CHANGE = 'INPUT_CHANGE';

const inputReducer = (state, action) => {

    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                touched: true
            };
        default:
            return state;
    }
};

const SliderInput = props => {

    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue ? props.initialValue : 0,
        touched: false
    });

    const {onInputChange, id} = props;

    useEffect(() => {
        onInputChange(id, props.options[inputState.value].value, true);
        if (inputState.touched) {
            onInputChange(id, props.options[inputState.value].value, true);
        }
    }, [props.options, inputState, onInputChange, id]);

    const changeHandler = values => {
        const value = parseInt(values.shift());

        dispatch({type: INPUT_CHANGE, value: value});
    };

    return (
        <div className="input input-slider">
            <label className="input-label">{props.label}{props.required === 1 && '*'}</label>
            {
                typeof inputState.value !== 'undefined' &&
                inputState.value !== '' &&
                props.options[inputState.value].description !== '' &&
                props.options[inputState.value].description !== null &&
                <p
                    className="input-description"
                    dangerouslySetInnerHTML={{ __html: props.options[inputState.value].description }}
                />
            }
            <Nouislider
                step={1}
                range={{min: 0, max: (props.options.length - 1)}}
                start={0}
                onChange={changeHandler}
                pips={{
                    mode: 'steps',
                    stepped: true,
                    density: 100,
                    filter: (value) => {
                        return 1
                    },
                    format: {
                        to: function (value) {
                            return props.options[value].label;
                        },
                        from: function (value) {
                            return props.options[value].label;
                        }
                    }
                }}
            />
        </div>
    );
};

export default SliderInput;
