/**
 * StyledInput a wrapper for NativeBase floatingLabel Input
 * https://docs.nativebase.io/Components.html#floating-label-headref
 *
 * @typedef {Object<string, any>} Props
 * @property {string} iconName name of icon
 * @property {string} iconSet Iconset from react-native-vector-icons
 *
 * @returns {*}
 */

import React, {useReducer, useEffect} from 'react';

const INPUT_CHANGE = 'INPUT_CHANGE';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: ((action.required && action.value) || !action.required),
                touched: true
            };
        default:
            return state;
    }
};

const NewsletterInput = props => {

    const [inputState, dispatch] = useReducer(inputReducer, {
        value: false,
        isValid: (!props.required),
        touched: false
    });

    // const [errorIcon, setErrorIcon] = useState('');
    // const [errorIconColor, setErrorIconColor] = useState('');
    const {onInputChange, id} = props;

    useEffect(() => {

        if (inputState.touched) {
            onInputChange(id, inputState.value, inputState.isValid);
        }
        if (inputState.isValid === false && inputState.touched) {
            // DoToast('danger', props.errorText);
            // setErrorIcon('close-circle');
            // setErrorIconColor(Colors.alarmColor);
        }
        if (inputState.isValid && inputState.touched) {
            // setErrorIcon('checkmark-circle');
            // setErrorIconColor(Colors.secondaryColor);
        }
    }, [inputState, onInputChange, id]);

    const changeHandler = event => {
        dispatch({type: INPUT_CHANGE, value: event.target.checked, required: props.required});
    };

    return (
        <div className={'input input-newsletter input-checkbox option' + ((inputState.isValid === false || typeof inputState.isValid === 'undefined') && (inputState.touched || props.submitted) ? 'error' : '')} >
            <input
                id={'newsletter-checkbox'}
                className={'option-input'}
                name={'newsletter-checkbox'}
                value={1}
                required={props.required === 1}
                type={'checkbox'}
                onChange={changeHandler}
            />
            <label
                className={'option-label'}
                htmlFor={'newsletter-checkbox'}
                dangerouslySetInnerHTML={{ __html: props.text.substring(3, (props.text.length - 4)) + (props.required === 1 ? ' *' : '')}}
            />

        </div>
    );
};

export default NewsletterInput;
