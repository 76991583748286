/**
 * StyledInput a wrapper for NativeBase floatingLabel Input
 * https://docs.nativebase.io/Components.html#floating-label-headref
 *
 * @typedef {Object<string, any>} Props
 * @property {string} iconName name of icon
 * @property {string} iconSet Iconset from react-native-vector-icons
 *
 * @returns {*}
 */

import React, {useMemo, useReducer} from 'react';

const INPUT_CHANGE = 'INPUT_CHANGE';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: !state.value,
            };
        default:
            return state;
    }
};

const TermsInput = props => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: (props.initialValue ? props.initialValue : false),
    });

    const changeHandler = event => {
        dispatch({type: INPUT_CHANGE, value: event.target.value});
        if(typeof props.changeHandler === 'function') {
            props.changeHandler(!inputState.value);
        }
    };

    // remove surrounding <p> and <label> tags
    const termsText = useMemo(() => {
        return props.text.slice(3, -4).replace(/<\/?label>/, '').trim() + ' *';
    }, [props.text]);

    if(termsText) {
        return (
            <div id={'module-terms-condition-checkbox-wrapper'} className={'input input-terms input-checkbox ' + ((inputState.isValid === false || typeof inputState.isValid === 'undefined') && (inputState.touched || props.submitted) ? 'error' : '')} >
                <input
                    id={'module-terms-condition-checkbox'}
                    name={'terms_conditions'}
                    value={0}
                    type={'checkbox'}
                    required={1}
                    onChange={changeHandler}
                />
                <label
                    htmlFor={'module-terms-condition-checkbox'}
                    dangerouslySetInnerHTML={{ __html: termsText}}
                />
            </div>
        );
    }
};

export default TermsInput;
