import {
    INIT_PROCESS,
    INIT_TOOL,
    SET_SUCCESS,
    PROCEED,
    CONTINUE_PROCESS,
    PROCESS,
    GET_NEXT,
    START_LOADING,
    END_LOADING, SET_TARGET_DOMAIN, NOT_AVAILABLE, DEFAULT_INIT, UPDATE_PROCESS_ID
} from "../actions/App";
import {
    APPROVED,
    CUSTOMER_BACK, EMAIL_CONFIRMED,
    INIT_CUSTOMER,
    LOGOUT_CUSTOMER,
    MODULE_LOGIN, PASSWORD_RESET,
    PROFILE_LOGIN,
    VIEW_CUSTOMER, VIEW_FORGOT
} from "../actions/Customer";

import {DISPLAY_DOWNLOADS} from "../actions/Download";

import {NEXT_GROUP} from "../actions/Checklist";

const initialState = {
    loading: 0,
    toolId: null,
    tool: null,
    processes: null,
    processId: null,
    submissionId: null,
    module: null,
    moduleId: null,
    view: null,
    haveSuccess: false,
    success: null,
    history: [],
    profileView: false,
    userInit: false,
    defaultInit: false,
    processInit: false,
    processable: false,
    targetDomain: null,
    style: null,
    refresh: false,
}

const SET_PROCESS_ID_ACTIONS = [
    CONTINUE_PROCESS,
    INIT_PROCESS,
    // GET_NEXT, // @TODO CHECK IF NECESSARY
    // EMAIL_CONFIRMED, // @TODO CHECK IF NECESSARY
    // PROCESS, // @TODO CHECK IF NECESSARY
    // PROCEED, // @TODO CHECK IF NECESSARY
    UPDATE_PROCESS_ID,
];

// eslint-disable-next-line
export default (state = initialState, action) => {
    let newState = {};

    if (typeof action.processable !== 'undefined') {
        newState.processable = action.processable;
    }

    if (action.submissionId) {
        if(newState.submissionId !== action.submissionId) {
            // console.warn('CHANGED SUBMISSION ID', newState, action)
            newState.submissionId = action.submissionId;
        }
    }

    if (SET_PROCESS_ID_ACTIONS.includes(action.type) &&
        action.processId
    ) {
        if(newState.processId !== action.processId) {
            // console.warn('CHANGED PROCESS ID', newState, action)
            newState.processId = action.processId;
        }
    }

    switch (action.type) {
        case SET_TARGET_DOMAIN:
            newState.targetDomain = action.targetDomain;
            break;

        case START_LOADING:
            newState.loading = state.loading + 1;
            break;

        case END_LOADING:
            if(state.loading > 0) {
                newState.loading = state.loading - 1;
            }
            break;

        case CONTINUE_PROCESS:
            newState.module = action.module;
            newState.view = action.module.name;
            newState.success = action.success;
            newState.tool = action.tool;
            newState.processInit = true;
            break;

        case APPROVED:
            newState.moduleId = action.moduleId;
            newState.success = action.success;
            newState.processInit = true;
            break;

        case INIT_PROCESS:
            newState.moduleId = action.moduleId;
            newState.success = null;
            newState.processInit = true;
            newState.style = action.style;
            break;

        case INIT_TOOL:
            newState.tool = action.tool;
            newState.processes = action.processes;
            newState.view = 'tool';
            newState.processInit = true;
            newState.style = action.style;
            break;

        case GET_NEXT:
            newState.module = action.module;
            newState.moduleId = action.module.data.module.id;
            newState.view = action.module.name;
            newState.haveSuccess = action.haveSuccess;
            newState.success = null;
            break;

        case EMAIL_CONFIRMED:
            newState.moduleId = action.moduleId;
            newState.success = action.success;
            newState.processInit = true;
            break;

        case PROCESS:
            newState.moduleId = action.moduleId;
            newState.success = action.success;
            newState.profileView = false;
            break;

        case PROCEED:
            newState.module = action.module;
            newState.view = action.module.name;
            newState.success = action.success;
            break;

        case SET_SUCCESS:
            newState.success = null;
            break;

        case PASSWORD_RESET:
            // RESET ALL FOR RESTART APP AFTER UPDATE PW
            newState.submissionId = null;
            newState.module = null;
            newState.success = null;
            newState.history = [];
            newState.userInit = false;
            newState.processInit = false;
            newState.defaultInit = true;
            newState.view = action.view;
            newState.profileView = true;
            break;

        case VIEW_CUSTOMER:
        case VIEW_FORGOT:
        case DISPLAY_DOWNLOADS:
            newState.view = action.view;
            newState.profileView = true;
            break;

        case MODULE_LOGIN:
        case PROFILE_LOGIN:
        case CUSTOMER_BACK:
            newState.view = state.module.name;
            newState.profileView = false;
            break;

        case LOGOUT_CUSTOMER:
            newState.submissionId = null;
            newState.module = null;
            newState.view = null;
            newState.success = null;
            newState.history = [];
            newState.profileView = false;
            newState.userInit = false;
            newState.processInit = false;
            newState.defaultInit = true;
            break;

        case INIT_CUSTOMER:
            newState.userInit = true;
            if(typeof action.style !== 'undefined') {
                newState.style = action.style;
            }
            break;

        case NEXT_GROUP:
            let moduleData = state.module;
            moduleData.data.group = action.group;
            moduleData.data.questions = action.questions;
            newState.module = moduleData;
            break;

        case NOT_AVAILABLE:
            newState.view = 'error';
            break;

        case DEFAULT_INIT:
            newState.defaultInit = true;
            break;

        case UPDATE_PROCESS_ID:
            break;

        default:
            break;
    }

    if(action.resetErrorSubmission) {
        console.error('RESET ERROR SUBMISSION', action)
        newState.submissionId = null;
        newState.module = null;
        newState.view = null;
        newState.success = null;
        newState.history = [];
        newState.profileView = false;
        newState.processInit = false;
        newState.defaultInit = true;
    }

    return {
        ...state,
        ...newState
    }
};