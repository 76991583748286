/**
 * StyledInput a wrapper for NativeBase floatingLabel Input
 * https://docs.nativebase.io/Components.html#floating-label-headref
 *
 * @typedef {Object<string, any>} Props
 * @property {string} iconName name of icon
 * @property {string} iconSet Iconset from react-native-vector-icons
 *
 * @returns {*}
 */

import React, {useReducer, useEffect} from 'react';

const INPUT_CHANGE = 'INPUT_CHANGE';
// const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            const newValue = state.value.slice();
            if(!newValue.includes(action.value)) {
                    newValue.push(action.value)
            } else {
                const index = newValue.indexOf(action.value)
                if(index > -1) {
                    newValue.splice(index, 1)
                }
            }

            let valid = false;
            if((action.required && newValue.length) || !action.required) {
                valid = true;
            }

            return {
                ...state,
                value: newValue,
                isValid: valid,
                touched: true
            };
        default:
            return state;
    }
};

const CheckboxesInput = (props) => {

    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue ? props.initialValue : [],
        isValid: ((props.required === 1 && props.initialValue) || props.required === 0),
        touched: false
    });

    // const [errorIcon, setErrorIcon] = useState('');
    // const [errorIconColor, setErrorIconColor] = useState('');
    const {onInputChange, id} = props;

    useEffect(() => {

        if (inputState.touched) {
            onInputChange(id, inputState.value, inputState.isValid);
        }
        if (inputState.isValid === false && inputState.touched) {
            // DoToast('danger', props.errorText);
            // setErrorIcon('close-circle');
            // setErrorIconColor(Colors.alarmColor);
        }
        if (inputState.isValid && inputState.touched) {
            // setErrorIcon('checkmark-circle');
            // setErrorIconColor(Colors.secondaryColor);
        }
    }, [inputState, onInputChange, id]);

    const changeHandler = event => {
        let toAdd;
        if(event.target.checked) {
            toAdd = true;
        } else {
            toAdd = false;
        }

        const value = event.target.value;
        dispatch({type: INPUT_CHANGE, value: value, required: props.required, toAdd: toAdd});
    };

    return (
        <div className={'input input-checkboxes ' + ((inputState.isValid === false || typeof inputState.isValid === 'undefined') && (inputState.touched || props.submitted) ? 'error' : '')} >
            <span className="input-label">{props.label} {props.required === 1 && '*'}</span>
            {props.options.map((option) => (
                <div key={option.value} className="option">
                    <input
                        className="option-input"
                        id={'pc_field_'+option.value}
                        name={props.id + '[]'}
                        value={option.value}
                        type={'checkbox'}
                        onChange={changeHandler}
                    />
                    <label htmlFor={'pc_field_'+option.value} className="option-label">
                        {option.label}
                    </label>
                    {
                        option.description &&
                        <span
                            className="input-description"
                            dangerouslySetInnerHTML={{ __html: option.description }}
                        />
                    }
                </div>
            ))}
        </div>
    );
};

export default CheckboxesInput;
