import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {proceedAction} from "../../../store/actions/App";
import TermsInput from "../../ui/TermsInput";
import toast from "react-hot-toast";
import ProcessBtn from "../../ui/ProcessBtn";

const Email = (props) => {
    // console.log('Email')

    const dispatch = useDispatch();

    const processId = useSelector(state => state.App.processId);
    const submissionId = useSelector(state => state.App.submissionId);
    const module = useSelector(state => state.App.module);
    const requestPath = 'pc-api-' + module.name + '/submit';

    const [emailSend, updateEmailSend] = useState(false);
    const [submitted, updateSubmitted] = useState(false);

    function handleProceed (event)  {
        updateSubmitted(true);

        if((module.data.module.display_terms_conditions && module.data.module.terms_conditions)) {

            if(document.querySelector('#module-terms-condition-checkbox').checked) {
                dispatch(proceedAction(requestPath, processId, submissionId, module.data.module.id, 1));
            } else {
                toast.error('Nicht alle erforderlichen Felder ausgefüllt.');
            }

        } else {
            dispatch(proceedAction(requestPath, processId, submissionId, module.data.module.id));
        }
    }

    useEffect(() => {
        if(!emailSend && module.data.email.display_email_page === 0) {
            updateEmailSend(true);
        }
    });

    useEffect(() => {
        if(!submitted && emailSend) {
            updateSubmitted(true);
            dispatch(proceedAction(requestPath, processId, submissionId, module.data.module.id));

        }
    }, [dispatch, submitted, emailSend]);

    if(module.data.email.display_email_page === 1) {
        return (
            <div>
                {
                    module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 0 &&
                    <TermsInput
                        text={module.data.module.terms_conditions}
                        submitted={submitted}
                    />
                }
                <ProcessBtn btnText={module.data.module.submit_btn_text} handleProceed={handleProceed}/>
            </div>
        );
    } else {
        return (
            <div>
                LOADING...
            </div>
        );
    }

}

export default Email;