import {doServerRequest, startLoading, stopLoading} from "./Webservice";
import {PROCESS} from "./App";
import {toastResponseMessages} from "../../components/ui/Message";

export const CHECKLIST_ROUTE = 'pc-api-checklist/';

export const NEXT_GROUP = 'NEXT_GROUP';

export const proceedAction = (processId, submissionId, moduleId, groupId, data) => {
    return async (dispatch) => {
        startLoading(dispatch);

        data.processId = processId
        data.submissionId = submissionId
        data.moduleId = moduleId
        data.groupId = groupId

        const response = await doServerRequest(CHECKLIST_ROUTE + 'submit', data);

        if (response && response.status === 'nextGroup') {
            dispatch({
                type: NEXT_GROUP,
                ...response
            });
        } else if (response && response.status === 'success') {
            dispatch({
                type: PROCESS,
                ...response
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};