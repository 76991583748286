import {doServerRequest, startLoading, stopLoading} from "./Webservice";
import {INIT_CUSTOMER} from "./Customer";
import {toastResponseMessages} from "../../components/ui/Message";

export const APP_ROUTE = 'pc-api/';

export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const INIT_PROCESS = 'INIT_PROCESS';
export const INIT_TOOL = 'INIT_TOOL';
export const SET_SUCCESS = 'SET_SUCCESS';
export const PROCEED = 'PROCEED';
export const PROCESS = 'PROCESS';
export const GET_NEXT = 'GET_NEXT';
export const CONTINUE_PROCESS = 'CONTINUE_PROCESS';
export const SET_TARGET_DOMAIN = 'SET_TARGET_DOMAIN';
export const NOT_AVAILABLE = 'NOT_AVAILABLE';
export const DEFAULT_INIT = 'DEFAULT_INIT';
export const UPDATE_PROCESS_ID = 'UPDATE_PROCESS_ID';

export const continueProcessAction = (processId, data, errorFunction = null) => {
    return async (dispatch) => {
        startLoading(dispatch);

        data.processId = processId;

        const response = await doServerRequest(APP_ROUTE + 'continue-process', data);

        if (response && response.status === 'success') {
            dispatch({
                type: CONTINUE_PROCESS,
                ...response,
            });

            if(response.user !== null) {
                dispatch({
                    type: INIT_CUSTOMER,
                    user: response.user,
                });
            }
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
            if(typeof errorFunction === 'function') {
                errorFunction(response);
            }
        }

        stopLoading(dispatch, response);
    };
};

export const processAction = (processId, submissionId, moduleId) => {
    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(APP_ROUTE + 'process',{
            processId: processId,
            submissionId: submissionId,
            moduleId: moduleId,
        });

        if (response && response.status === 'success') {
            dispatch({
                type: GET_NEXT,
                ...response,
                processId: processId
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const initProcessAction = (processId, user) => {
    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(APP_ROUTE + 'init-process', {
            processId: processId,
            user: user.id,
            });

        if (response && response.status === 'success') {
            dispatch({
                type: INIT_PROCESS,
                ...response,
                processId: processId
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);

            dispatch({
                type: NOT_AVAILABLE,
            });
        }

        stopLoading(dispatch, response);
    };
};

export const initToolAction = (toolId) => {
    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(APP_ROUTE + 'init-tool',
            {toolId: toolId});

        if (response && response.status === 'success') {
            dispatch({
                type: INIT_TOOL,
                ...response,
                toolId: toolId
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const proceedAction = (path, processId, submissionId, moduleId, termsChecked = null) => {
    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(path,
            {
                processId: processId,
                submissionId: submissionId,
                moduleId: moduleId,
                termsConditions: termsChecked,
            });

        if (response && response.status === 'success') {

            dispatch({
                type: PROCESS,
                ...response
            });

        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};