import React from 'react';
import {useSelector} from "react-redux";
import PcConfig from '../../utility/PcConfig';

const Tool = (props) => {

    const view = useSelector(state => state.App.view);
    const tool = useSelector(state => state.App.tool);
    const processes = useSelector(state => state.App.processes);

    if(view === 'tool') {
        return (
            <div className="component">
                {/*{tool.heading && <h1>{tool.heading}</h1>}*/}
                {
                    tool.image &&
                    <img
                        width={(tool.image_width * 75) + 'px'}
                        src={PcConfig.IMAGES_ROOT + '/images/tool/' + tool.id + '/' +  tool.image}
                        alt={'tool_img'}
                    />
                }
                {tool.description &&  <div dangerouslySetInnerHTML={{ __html:tool.description }} />}
                <hr/>
                <hr/>
                {processes.map((process) => {
                        return (
                            <div key={process.id}>
                                {/*{process.heading && <h2>{process.heading}</h2>}*/}
                                {
                                    process.image &&
                                    <img
                                        width={(process.image_width * 75) + 'px'}
                                        src={PcConfig.IMAGES_ROOT + '/images/process/' + process.id + '/' +  process.image}
                                        alt={'tool_img'}
                                    />
                                }
                                {process.description &&  <div dangerouslySetInnerHTML={{ __html:process.description }} />}
                                {
                                    process.url &&
                                    <a href={process.url} target="_blank" rel="noreferrer">Ausführen</a>
                                }
                                <hr/>
                            </div>

                        );
                    }
                )}
            </div>
        );
    }
}

export default Tool;