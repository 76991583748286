import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';
import { isValidElement } from 'react';
import { join } from '../../utility/Css';

const Button = forwardRef(({
	primary = false,
	secondary = !primary,
	icon,
	iconPosition = 'right',
	className = '',
	children,
	...props
}, ref) => {
	const iconRight = icon && iconPosition === 'right';
	const iconLeft = icon && iconPosition === 'left';

	const iconElement = !!icon && (
		<div className="icon-container">
			{isValidElement(icon) ? (
				icon
			) : (
				<FontAwesomeIcon icon={icon} className="icon" />
			)}
		</div>
	)

	return (
		<button
			ref={ref}
			type="button"
			className={join(
				'button',
				primary && 'primary',
				secondary && 'secondary',
				iconRight && 'icon-right',
				iconLeft && 'icon-left',
				className
			)}
			{...props}
		>	
			{iconPosition === 'left' && iconElement}
			<span className="content">
				{children}
			</span>
			{iconPosition === 'right' && iconElement}
		</button>
	);
});

export default Button;