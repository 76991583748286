import React from 'react';
import {useSelector} from "react-redux";

const ErrorPage = (props) => {

    const view = useSelector(state => state.App.view);

    if(view === 'error') {
        return (
            <div>Der gewünschte Dienst steht derzeit leider nicht zur Verfügung.</div>
        );
    }
}

export default ErrorPage;