import React, {useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {proceedAction} from "../../../store/actions/App";
import TermsInput from "../../ui/TermsInput";
import toast from "react-hot-toast";
import ProcessBtn from "../../ui/ProcessBtn";
import PcConfig from '../../../utility/PcConfig';

const Freetext = () => {
    // console.log('Freetext')

    const dispatch = useDispatch();

    const processId = useSelector(state => state.App.processId);
    const submissionId = useSelector(state => state.App.submissionId);
    const module = useSelector(state => state.App.module);
    const requestPath = 'pc-api-' + module.name + '/submit'
    const [submitted, updateSubmitted] = useState(false);

    function handleProceed (event)  {
        updateSubmitted(true);
        if((module.data.module.display_terms_conditions && module.data.module.terms_conditions)) {

            if(document.querySelector('#module-terms-condition-checkbox').checked) {
                dispatch(proceedAction(requestPath, processId, submissionId, module.data.module.id, 1));
            } else {
                toast.error('Nicht alle erforderlichen Felder ausgefüllt.');
            }

        } else {
            dispatch(proceedAction(requestPath, processId, submissionId, module.data.module.id));
        }
    }

    if(module.data.freetext.image !== '' && module.data.freetext.image !== null && module.data.freetext.image !== 'undefined') {
        return (
            <div className="freetext fields-container">
                {
                    module.data.freetext.image_width ?
                        <img
                            className="freetext-image"
                            width={(module.data.freetext.image_width * 150) + 'px'}
                            src={PcConfig.IMAGES_ROOT + '/images/freetext/' + module.data.module.id + '/' +  module.data.freetext.image}
                            alt={'freetext_img'}
                        />
                        :
                        <img
                            className="freetext-image"
                            src={PcConfig.IMAGES_ROOT + '/images/freetext/' + module.data.module.id + '/' +  module.data.freetext.image}
                            alt={'freetext_img'}
                        />
                }

                {
                    module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 1 &&
                    <TermsInput
                        text={module.data.module.terms_conditions}
                        submitted={submitted}
                    />
                }

                <ProcessBtn btnText={module.data.module.submit_btn_text} handleProceed={handleProceed}/>
            </div>
        );
    } else {
        return (
            <div className="freetext fields-container">
                {
                    module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 1 &&
                    <TermsInput
                        text={module.data.module.terms_conditions}
                        submitted={submitted}
                    />
                }

                <ProcessBtn btnText={module.data.module.submit_btn_text} handleProceed={handleProceed}/>
            </div>
        );
    }
}

export default Freetext