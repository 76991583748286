import { useRef } from 'react';

/**
 * Allows storing a value for access in the next render.
 * 
 * @param {*} nextValue - The value to return on the next render.
 * @param {*} initialValue - The value to return on the first render.
 * @returns {*} The value that was passed as 'nextValue' on the previous render.
 */
export default function usePreviousRender(nextValue, initialValue) {
    const ref = useRef(initialValue);
    const previousValue = ref.current;

    ref.current = nextValue;

    return previousValue;
}