/**
 * StyledInput a wrapper for NativeBase floatingLabel Input
 * https://docs.nativebase.io/Components.html#floating-label-headref
 *
 * @typedef {Object<string, any>} Props
 * @property {string} iconName name of icon
 * @property {string} iconSet Iconset from react-native-vector-icons
 *
 * @returns {*}
 */

import React, {useReducer, useEffect} from 'react';

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
    // console.log('action')
    // console.log(action)
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid
            };
        case INPUT_BLUR:
            return {
                ...state,
                touched: true
            };
        default:
            return state;
    }
};

const TextareaInput = props => {

    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue ? props.initialValue : '',
        isValid: ((props.required === 1 && props.initialValue) || props.required === 0),
        touched: false
    });

    // const [errorIcon, setErrorIcon] = useState('');
    // const [errorIconColor, setErrorIconColor] = useState('');
    const {onInputChange, id} = props;

    useEffect(() => {
        // if (inputState.touched) {
            onInputChange(id, inputState.value, inputState.isValid);
        // }
        if (inputState.isValid === false && inputState.touched) {
            // DoToast('danger', props.errorText);
            // setErrorIcon('close-circle');
            // setErrorIconColor(Colors.alarmColor);
        }
        if (inputState.isValid && inputState.touched) {
            // setErrorIcon('checkmark-circle');
            // setErrorIconColor(Colors.secondaryColor);
        }
    }, [inputState, onInputChange, id]);

    const changeHandler = event => {
        const text = event.target.value ;

        // console.log('TEXT CHANGE');

        // const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = true;
        if (props.required && text.trim().length === 0) {
            isValid = false;
        }
        // console.log(text)
        // if (props.type === 'email' && !emailRegex.test(text.toLowerCase())) {
        //     isValid = false;
        // }
        if (props.min != null && +text < props.min) {
            isValid = false;
        }
        if (props.max != null && +text > props.max) {
            isValid = false;
        }
        if (props.minLength != null && text.length < props.minLength) {
            isValid = false;
        }

        if (props.isInteger === true && text && text !== parseInt(text).toString()) {
            isValid = false;
        }

        dispatch({type: INPUT_CHANGE, value: text, isValid: isValid});
    };

    const lostFocusHandler = () => {
        dispatch({type: INPUT_BLUR});
    };

    // // choose Icon Set
    // let iconSet;
    // if (props.iconSet != null) {
    //     iconSet = props.iconSet;
    // } else {
    //     iconSet = "Ionicons";
    // }
// console.log('FIELD',props.label, props.required)

    return (
        <div className={'input input-textarea ' + ((inputState.isValid === false || typeof inputState.isValid === 'undefined') && (inputState.touched || props.submitted) ? 'error' : '')} >
            <label className="input-label">{props.label}{props.required === 1 && '*'}</label>
            <textarea
                className="input-element"
                id={'pc_field_'+props.id}
                cols={40}
                rows={5}
                required={props.required === 1}
                minLength={props.minLength}
                maxLength={props.maxLength}
                value={inputState.value}
                onChange={changeHandler}
                onBlur={lostFocusHandler}
            />
            {
                props.description &&
                <p className="input-description" dangerouslySetInnerHTML={{ __html: props.description }} />
            }
        </div>
    );
};

export default TextareaInput;
