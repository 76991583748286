import { faArrowRight as fatArrowRight } from '@fortawesome/pro-thin-svg-icons';
import React  from 'react';
import {useSelector} from "react-redux";
import Button from './Button';

const ProcessBtn = (props) => {

    const haveSuccess = useSelector(state => state.App.haveSuccess);
    const processable = useSelector(state => state.App.processable);

    // console.log('processable', processable)

    if (processable || (haveSuccess && !props.isSuccess)) {
        return (
            <Button primary icon={fatArrowRight} onClick={props.handleProceed}>
                {props.btnText ? props.btnText : 'Weiter'}
            </Button>
        );
    }
}

export default ProcessBtn;