import {doServerRequest, startLoading, stopLoading} from "./Webservice";
import {PROCESS} from "./App";
import {toastResponseMessages} from "../../components/ui/Message";

export const BRANCH_ROUTE = 'pc-api-branch/';

export const proceedAction = (processId, submissionId, moduleId, branchNextId, data) => {
    return async (dispatch) => {
        startLoading(dispatch);

        data.processId = processId
        data.submissionId = submissionId
        data.moduleId = moduleId
        data.branchNextId = branchNextId

        const response = await doServerRequest(BRANCH_ROUTE + 'submit', data);

        if (response && response.status === 'success') {
            dispatch({
                type: PROCESS,
                ...response
            });

        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};