import {
    faClockOne as fatClockOne,
    faClockTwo as fatClockTwo,
    faClockThree as fatClockThree,
    faClockFour as fatClockFour,
    faClockFive as fatClockFive,
    faClockSix as fatClockSix,
    faClockSeven as fatClockSeven,
    faClockEight as fatClockEight,
    faClockNine as fatClockNine,
    faClockTen as fatClockTen,
    faClockEleven as fatClockEleven,
    faClockTwelve as fatClockTwelve,
} from '@fortawesome/pro-thin-svg-icons';

const iconAndRotationsByClockHandDistance = {
	0: [fatClockTwelve, 0],
	5: [fatClockEleven, 330],
	10: [fatClockTen, 300],
	15: [fatClockNine, 270],
	20: [fatClockEight, 240],
	25: [fatClockSeven, 210],
	30: [fatClockSix, 180],
	35: [fatClockFive, 150],
	40: [fatClockFour, 120],
	45: [fatClockThree, 90],
	50: [fatClockTwo, 60],
	55: [fatClockOne, 30]
}

export const clockIconGetByMoment = moment => {
	const hour = moment.hour();
	const minute = Math.floor(moment.minute() / 5) * 5;
	const handDistance = (((minute - 5 * hour) % 60) + 60) % 60;
	
	const [icon, iconRotation] = iconAndRotationsByClockHandDistance[handDistance];
	const rotation = 30 * hour - iconRotation;

	return [icon, `${rotation}deg`];
}