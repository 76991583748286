/**
 * StyledInput a wrapper for NativeBase floatingLabel Input
 * https://docs.nativebase.io/Components.html#floating-label-headref
 *
 * @typedef {Object<string, any>} Props
 * @property {string} iconName name of icon
 * @property {string} iconSet Iconset from react-native-vector-icons
 *
 * @returns {*}
 */

import React, {useReducer, useEffect} from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid
            };
        case INPUT_BLUR:
            return {
                ...state,
                touched: true
            };
        default:
            return state;
    }
};

const CaptchaInput = props => {

    const [inputState, dispatch] = useReducer(inputReducer, {
        value: (props.initialValue ? props.initialValue : ''),
        isValid: ((props.required === 1 && props.initialValue) || props.required === 0),
        touched: false,
    });

    useEffect(() => {
        if(inputState.init !== true) {
            loadCaptchaEnginge(6);
        }
    }, [inputState.init]);

    const {onInputChange, id} = props;

    useEffect(() => {
        onInputChange(inputState.isValid);
    }, [inputState, onInputChange, id]);

    const textChangeHandler = event => {
        const text = event.target.value ;
        let isValid = true;
        if (validateCaptcha(text, false) !== true) {
            isValid = false;
        }
        dispatch({type: INPUT_CHANGE, value: text, isValid: isValid});
    };

    const lostFocusHandler = () => {
        dispatch({type: INPUT_BLUR});
    };

    return (
        <div className={'input input-captcha ' + ((inputState.isValid === false || typeof inputState.isValid === 'undefined') && (inputState.touched || props.submitted) ? 'error' : '')} >
            <label className="input-label">{'Captcha'} {'*'}</label>
            <div className="input-captcha-inner-container">
                <LoadCanvasTemplateNoReload />
                <input
                    className="input-element"
                    type={'text'}
                    required={true}
                    value={inputState.value}
                    onChange={textChangeHandler}
                    onBlur={lostFocusHandler}
                />
            </div>
        </div>
    );
};

export default CaptchaInput;
