import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {proceedAction} from "../../../store/actions/Appointment";
import toast from "react-hot-toast";
import moment from "moment";
import TermsInput from "../../ui/TermsInput";
import InfoBlock from '../../ui/InfoBlock';
import DateTimeList from '../../ui/DateTimeList';
import { useRef } from 'react';
import NextButton from '../../ui/NextButton';

const Appointment = (props) => {

    // console.log('Appointment')

    const dispatch = useDispatch();

    const processId = useSelector(state => state.App.processId);
    const submissionId = useSelector(state => state.App.submissionId);
    const module = useSelector(state => state.App.module);
    const moduleId = useSelector(state => state.App.moduleId);

    const [selectedTime, updateSelectedTime] = useState(null);
    const [submitted, updateSubmitted] = useState(false);

    const nextButtonRef = useRef();

    useEffect(() => {
        updateSelectedTime(null);
    }, [moduleId, module]);

    const selectHandler = useCallback(time => {
        if (time) {
            nextButtonRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }

        updateSelectedTime(time);
    }, []);

    function handleProceed (event)  {
        if(!selectedTime) {
            toast.error('Bitte wählen Sie einen Termin.');
            return;
        }

        if((module.data.module.display_terms_conditions && module.data.module.terms_conditions)) {

            if(document.querySelector('#module-terms-condition-checkbox').checked) {
                updateSubmitted(true);
                dispatch(proceedAction(processId, submissionId, module.data.module.id, {appointment: selectedTime.value,  termsConditions: 1}));
            } else {
                toast.error('Nicht alle erforderlichen Felder ausgefüllt.');
            }

        } else {
            updateSubmitted(true);
            dispatch(proceedAction(processId, submissionId, module.data.module.id, {appointment: selectedTime.value}));
        }
    }

    moment.updateLocale('de', {
        weekdays : [
            "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"
        ]
    });

    const hours = Math.floor(module.data.type.duration /60);
    const minutes = module.data.type.duration % 60;

    return (
        <div id={'modulewrapper'}>
            <InfoBlock
                headingLevel={2}
                heading={module.data.type.heading}
                description={module.data.type.description}
            />
            <div className="appointment fields-container">
                <span className="appointment-duration">
                    Dauer: {hours ? (hours + 'h ' + minutes + 'min') : (minutes + 'min')}
                </span>
                <DateTimeList
                    timeSlots={module.data.slots}
                    selectedOption={selectedTime}
                    onSelect={selectHandler}
                />
                {
                    module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 0 &&
                    <TermsInput
                        text={module.data.module.terms_conditions}
                        submitted={submitted}
                    />
                }
                <NextButton ref={nextButtonRef} disabled={!selectedTime} onClick={handleProceed}>
                    {module.data.module.submit_btn_text}
                </NextButton>
            </div>
        </div>
    );
}

export default Appointment