import React, {PureComponent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    ResponsiveContainer
} from 'recharts';
import {proceedAction} from "../../../store/actions/App";
import {downloadAction, generateEvaluationAction, uploadDiagramm} from "../../../store/actions/Evaluation";
import TermsInput from "../../ui/TermsInput";
import toast from "react-hot-toast";
import ProcessBtn from "../../ui/ProcessBtn";
import Button from '../../ui/Button';
import {faArrowDownToLine as fatArrowDownToLine} from '@fortawesome/pro-thin-svg-icons';
import Heading from "../../ui/Heading";

class CustomizedXAxisTick extends PureComponent {
    render() {
        const {x, y, stroke, payload} = this.props;

        return (
            <text {...this.props} className="recharts-cartesian-axis-tick-value">
                {payload.value}
            </text>
        );
    }
}

class CustomizedYAxisTick extends PureComponent {
    render() {
        const {x, y, stroke, payload} = this.props;
        const words = payload.value.split(' ');
        let currentText = '';

        return (
            <text {...this.props} className="recharts-cartesian-axis-tick-value">
                {
                    words.map((word, index) => {
                        currentText += word + ' ';
                        if(currentText.length > 20) {
                            return (
                                <>
                                    <tspan>{currentText}</tspan>
                                    <br/>
                                </>
                            )
                        } else {
                            return null;
                        }

                    })
                }

            </text>
        );
    }
}

const Evaluation = () => {
    // console.log('Evaluation')

    const dispatch = useDispatch();

    const processId = useSelector(state => state.App.processId);
    const submissionId = useSelector(state => state.App.submissionId);
    const module = useSelector(state => state.App.module);
    const moduleId = useSelector(state => state.App.moduleId);
    const uploadedFilesCount = useSelector(state => state.Evaluation.uploadedFilesCount);
    const user = useSelector(state => state.Customer.user);

    const [chartsUploaded, updateChartsUploaded] = useState([]);

    const requestPath = 'pc-api-' + module.name + '/submit'

    const [submitted, updateSubmitted] = useState(false);
    const [generated, updateGenerated] = useState(false);

    const barChartData = {
        barCategoryGap: 30,
        barGap: 5,
        barSize: 15
    }

    useEffect(() => {
        updateChartsUploaded([]);
        updateGenerated(false);
        updateSubmitted(false);
    }, [moduleId]);


    useEffect(() => {
        if (!generated &&
            uploadedFilesCount === (
                module.data.result.filter(e => e.type === 'bar' || e.type === 'radar').length +
                module.data.generate.filter(e => e.type === 'bar' || e.type === 'radar').length
        )) {
            updateGenerated(true);
            const wrapper = document.getElementById('chart-rendering-wrapper');
            if(wrapper) {
                wrapper.remove();
            }

            dispatch(generateEvaluationAction(processId, submissionId, module.data.module.id));
        }
    }, [uploadedFilesCount, module.data.result, module.data.generate]);


    useEffect(() => {
        if (
            module.data.result.filter(e => e.type === 'bar' || e.type === 'radar').length ||
            module.data.generate.filter(e => e.type === 'bar' || e.type === 'radar').length
        ) {
            const chartCount = module.data.result.filter(e => e.type === 'bar' || e.type === 'radar').length +
                module.data.generate.filter(e => e.type === 'bar' || e.type === 'radar').length

            setTimeout(() => {

                module.data.result.map((element) => {
                    if (!chartsUploaded.includes(element.id)) {
                        switch (element.type) {
                            case 'bar':
                            case 'radar':
                                exportChart(element);
                                break;
                            default:
                                break;
                        }
                    }
                });
                module.data.generate.map((element) => {
                    if (!chartsUploaded.includes(element.id)) {
                        switch (element.type) {
                            case 'bar':
                            case 'radar':
                                exportChart(element);
                                break;
                            default:
                                break;
                        }
                    }
                });
            }, (chartCount * 50));
        }
    }, []);

    function handleProceed() {
        updateSubmitted(true);

        if ((module.data.module.display_terms_conditions && module.data.module.terms_conditions)) {

            if (document.querySelector('#module-terms-condition-checkbox').checked) {
                dispatch(proceedAction(requestPath, processId, submissionId, module.data.module.id, 1));
            } else {
                toast.error('Nicht alle erforderlichen Felder ausgefüllt.');
            }

        } else {
            dispatch(proceedAction(requestPath, processId, submissionId, module.data.module.id));
        }
    }

    function handleDownload(event) {
        dispatch(downloadAction(processId, submissionId, module.data.module.id));
    }

    function exportChart(diagram, count = 0) {

        let chartSVG = document.getElementById(diagram.id);

        if(chartSVG !== null) {
            let chartWrapper = chartSVG.parentElement;

            let upload = chartWrapper.cloneNode(true);

            // custom styling for PDF view
            if(diagram.type === 'bar') {
                const xAxis = upload.getElementsByClassName('xAxis');
                for (const ele of xAxis) {
                    let wrapper = ele.getElementsByClassName('recharts-layer recharts-cartesian-axis-tick');
                    for (const inner of wrapper) {
                        const text = inner.getElementsByTagName('text');
                        text[0].setAttribute('y', parseFloat(text[0].getAttribute('y')) + 10);
                    }
                }
                const yAxis = upload.getElementsByClassName('yAxis');
                for (const ele of yAxis) {
                    let wrapper = ele.getElementsByClassName('recharts-layer recharts-cartesian-axis-tick');
                    for (const inner of wrapper) {
                        const text = inner.getElementsByTagName('text');
                        text[0].setAttribute('y', parseFloat(text[0].getAttribute('y')) + 5);
                    }
                }
            }

            let svgURL = new XMLSerializer().serializeToString(upload);
            let svgBlob = new Blob([svgURL], {type: "plain/text;charset=utf-8"});
            dispatch(uploadDiagramm(processId, submissionId, module.data.module.id, module.data.evaluation, diagram.id, diagram.type + 'chart', svgBlob));

        } else {
            if(count < 10) {
                setTimeout(() => {
                    exportChart(diagram, (count + 1));
                }, 200);
            } else {
                toast.error('Diagramm konnte nicht erzeugt werden.');
                console.error('CHART NOT FOUND: ' + diagram.id)
            }
        }
    }

    return (
        <div id="evaluation-component" className="evaluation fields-container">
            <div id={'evaluation-content-wrapper'}>
                {
                    module.data.result.map((element, index) => {
                            switch (element.type) {
                                case 'bar':
                                    return (
                                        <div key={index}>
                                            {
                                                element.title &&
                                                <Heading level="4" className="diagram-heading">
                                                    {element.title}
                                                </Heading>
                                            }
                                            <ResponsiveContainer
                                                minWidth={600}
                                                width={'100%'}
                                                minHeight={
                                                    (element.dataset.length * element.data.length * barChartData.barSize) + // balken höhen
                                                    ((element.dataset.length - 1) * element.data.length * barChartData.barGap) + // abstände zwischen balken
                                                    ((element.data.length - 1) * barChartData.barCategoryGap) + // abstände zwischen gruppen
                                                    70 // legende
                                                }
                                            >
                                                <BarChart
                                                    id={element.id}
                                                    title={element.title}
                                                    desc={element.description}
                                                    data={element.data}
                                                    layout="vertical"
                                                    height={
                                                        (element.dataset.length * element.data.length * barChartData.barSize) + // balken höhen
                                                        ((element.dataset.length - 1) * element.data.length * barChartData.barGap) + // abstände zwischen balken
                                                        ((element.data.length - 1) * barChartData.barCategoryGap) + // abstände zwischen gruppen
                                                        70
                                                    }
                                                    barCategoryGap={barChartData.barCategoryGap}
                                                    barGap={barChartData.barGap}
                                                    barSize={barChartData.barSize}
                                                    margin={{top: 5, right: 0, bottom: 5, left: 10}}
                                                >
                                                    <CartesianGrid
                                                        horizontal={false}
                                                    />
                                                    <XAxis
                                                        domain={[0, element.domain]}
                                                        // ticks={ticks} tickCount={10}
                                                        // tick={<CustomizedXAxisTick/>}
                                                        type="number"
                                                    />
                                                    <YAxis
                                                        // tick={<CustomizedYAxisTick/>}
                                                        type="category"
                                                        dataKey={(ele) => {
                                                            if(ele.name.length > 18) {
                                                                return ele.name.substring(0, 15) + '...';
                                                            } else {
                                                                return ele.name;
                                                            }
                                                        }}
                                                        width={122}
                                                    />
                                                    <Legend/>
                                                    {
                                                        element.dataset.map((set, i) => {
                                                            return (
                                                                <Bar
                                                                    key={i}
                                                                    dataKey={set.title}
                                                                    fill={set.color}
                                                                    // isAnimationActive={false}
                                                                    // onAnimationEnd={barAnimationFinished}
                                                                />
                                                            );
                                                        })
                                                    }
                                                </BarChart>
                                            </ResponsiveContainer>
                                            {!!element.description && <div
                                                className="info-block-description"
                                                dangerouslySetInnerHTML={{__html: element.description}}
                                            />}
                                        </div>


                                    );
                                case 'radar':
                                    return (
                                        <div key={index}>
                                            <Heading level="4" className="diagram-heading">
                                                {element.title}
                                            </Heading>

                                            <RadarChart
                                                id={element.id}
                                                className="evaluation-chart chart-radar"
                                                outerRadius={150}
                                                width={450}
                                                height={350}
                                                title={element.title}
                                                desc={element.description}
                                                fillOpacity={0.6}
                                                data={element.data}
                                            >
                                                <PolarGrid/>
                                                <PolarAngleAxis
                                                    dataKey="name"
                                                />
                                                <PolarRadiusAxis
                                                    angle={90}
                                                    domain={[0, element.domain]}
                                                />
                                                {
                                                    element.dataset.map((set, i) => {
                                                        return (
                                                            <Radar
                                                                key={i}
                                                                dataKey={set.title}
                                                                fill={set.color}
                                                                // isAnimationActive={true}
                                                                // onAnimationEnd={radarAnimationFinished}
                                                            />
                                                        );
                                                    })
                                                }
                                                <Legend/>
                                            </RadarChart>

                                            {!!element.description && <div
                                                className="info-block-description"
                                                dangerouslySetInnerHTML={{__html: element.description}}
                                            />}
                                        </div>
                                    );
                                case 'text':
                                    return (
                                        <div style={{width: '100%'}} key={index} dangerouslySetInnerHTML={{__html: element.data}}/>
                                    )
                                default:
                                    return null;
                            }
                        }
                    )
                }

                {
                    module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 0 &&
                    <TermsInput
                        text={module.data.module.terms_conditions}
                        submitted={submitted}
                    />
                }

                <div className="button-row">
                    {
                        module.data.resultDownloadable === 1 && user.user_id &&
                        <Button

                            primary
                            icon={fatArrowDownToLine}
                            onClick={handleDownload}
                        >
                            Ergebnis herunterladen
                        </Button>
                    }
                    <ProcessBtn btnText={module.data.module.submit_btn_text} handleProceed={handleProceed}/>
                </div>
            </div>

            <div id="chart-rendering-wrapper" className="rendering-area">
                {
                    typeof module.data.generate !== 'undefined' &&
                    module.data.generate.map((element, index) => {
                        switch (element.type) {
                            case 'bar':
                                return (
                                    <div key={(index + 'rendering')}>
                                        <ResponsiveContainer
                                            minWidth={600}
                                            width={'100%'}
                                            minHeight={
                                                (element.dataset.length * element.data.length * barChartData.barSize) + // balken höhen
                                                ((element.dataset.length - 1) * element.data.length * barChartData.barGap) + // abstände zwischen balken
                                                ((element.data.length - 1) * barChartData.barCategoryGap) + // abstände zwischen gruppen
                                                70 // legende
                                            }
                                        >
                                            <BarChart
                                                id={element.id}
                                                title={element.title}
                                                desc={element.description}
                                                data={element.data}
                                                layout="vertical"
                                                height={
                                                    (element.dataset.length * element.data.length * barChartData.barSize) + // balken höhen
                                                    ((element.dataset.length - 1) * element.data.length * barChartData.barGap) + // abstände zwischen balken
                                                    ((element.data.length - 1) * barChartData.barCategoryGap) + // abstände zwischen gruppen
                                                    70
                                                }
                                                barCategoryGap={barChartData.barCategoryGap}
                                                barGap={barChartData.barGap}
                                                barSize={barChartData.barSize}
                                                margin={{top: 5, right: 0, bottom: 5, left: 10}}
                                            >
                                                <CartesianGrid
                                                    horizontal={false}
                                                />
                                                <XAxis
                                                    domain={[0, element.domain]}
                                                    // ticks={ticks} tickCount={10}
                                                    // tick={<CustomizedXAxisTick/>}
                                                    type="number"
                                                />
                                                <YAxis
                                                    // tick={<CustomizedYAxisTick/>}
                                                    type="category"
                                                    dataKey={(ele) => {
                                                        if(ele.name.length > 18) {
                                                            return ele.name.substring(0, 15) + '...';
                                                        } else {
                                                            return ele.name;
                                                        }
                                                    }}
                                                    width={122}
                                                />
                                                <Legend/>
                                                {
                                                    element.dataset.map((set, i) => {
                                                        return (
                                                            <Bar
                                                                key={i}
                                                                dataKey={set.title}
                                                                fill={set.color}
                                                                // isAnimationActive={false}
                                                                // onAnimationEnd={barAnimationFinished}
                                                            />
                                                        );
                                                    })
                                                }
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>


                                );
                            case 'radar':
                                return (
                                    <div key={index}>
                                        <RadarChart
                                            id={element.id}
                                            className="evaluation-chart chart-radar"
                                            outerRadius={150}
                                            width={450}
                                            height={350}
                                            fillOpacity={0.6}
                                            title={element.title}
                                            desc={element.description}
                                            data={element.data}
                                        >
                                            <PolarGrid/>
                                            <PolarAngleAxis
                                                dataKey="name"
                                            />
                                            <PolarRadiusAxis
                                                angle={90}
                                                domain={[0, element.domain]}
                                            />
                                            {
                                                element.dataset.map((set, i) => {
                                                    return (
                                                        <Radar
                                                            key={i}
                                                            dataKey={set.title}
                                                            fill={set.color}
                                                            fillOpacity={0.6}
                                                            // isAnimationActive={true}
                                                            // onAnimationEnd={radarAnimationFinished}
                                                        />
                                                    );
                                                })
                                            }
                                            <Legend/>
                                        </RadarChart>
                                    </div>
                                );
                            default:
                                return null;
                        }
                    })
                }
            </div>
        </div>
    );
}

export default Evaluation;