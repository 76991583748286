import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import DatainputComponents from "../../helpers/DatainputComponent";
import {proceedAction, uploadFileAction, SET_FILES_COUNT} from "../../../store/actions/Datainput";
import toast from "react-hot-toast";
import TermsInput from "../../ui/TermsInput";
import Loading from "../../ui/Loading";
import Button from '../../ui/Button';
import { faArrowRight as fatArrowRight } from '@fortawesome/pro-thin-svg-icons';
const Datainput = () => {
    // console.log('Datainput')

    const dispatch = useDispatch();

    const datainput = useSelector(state => state.Datainput);
    const processId = useSelector(state => state.App.processId);
    const submissionId = useSelector(state => state.App.submissionId);
    const module = useSelector(state => state.App.module);
    const fields = module.data.fields;

    const [submitStart, updateSubmitStart] = useState(false);
    const [submitted, updateSubmitted] = useState(false);

    const [currentDataset, updateCurrentDataset] = useState(null);
    const [currentConditions, updateCurrentConditions] = useState(null);
    const [currentConditionStates, updateCurrentConditionStates] = useState(null);

    useEffect(() => {
        const currentDatasetObj = {};
        const currentConditionObj = {};
        fields.forEach((field) => {
            if(field.type !== 'note') {
                currentDatasetObj[field.id] = '';
            }

            if(field.condition !== null) {
                currentConditionObj[field.id] = JSON.parse(field.condition);
            }
        });

        updateCurrentDataset(currentDatasetObj);
        updateCurrentConditions(currentConditionObj);

    }, [fields]);

    useEffect(() => {

        if(currentDataset !== null && currentConditions !== null) {
            const newConditionState = {};
            Object.keys(currentConditions).forEach((field) => {
                newConditionState[field] = checkCondition(field, currentConditions[field].condition_target, currentConditions[field].condition_condition, currentConditions[field].condition_values);
            });

            if(JSON.stringify(newConditionState) !== JSON.stringify(currentConditionStates)) {
                updateCurrentConditionStates(newConditionState);
            }
        }

    }, [currentDataset, currentConditions, currentConditionStates]);


    function checkCondition (field, target, condition, values) {

        let valid;
        if(parseInt(condition) === 1) {
            valid = false;
        } else if(parseInt(condition) === 0) {
            valid = true;
        }
        let targetField = fields.find(x => x.id === parseInt(target));

        if(typeof targetField !== 'undefined') {
            values.forEach(value => {
                if(parseInt(condition) === 1) {
                    // "IS" CONDITION
                    if(currentConditionStates !== null) {
                        // DEFAULT "IS" CONDITION
                        if(typeof currentConditionStates[target] !== 'undefined') {
                            // including "is target hidden" logic
                            if(currentConditionStates[target] === true) {
                                if(typeof currentDataset[target] !== 'undefined') {
                                    if(targetField.type === 'checkboxes') {
                                        if(currentDataset[target].includes(value) ) {
                                            valid = true;
                                        }
                                    } else {
                                        if(parseInt(currentDataset[target]) === parseInt(value)) {
                                            valid = true;
                                        }
                                    }
                                }
                            }
                        } else {
                            // excluding "is target hidden" logic
                            if(typeof currentDataset[target] !== 'undefined') {
                                if(targetField.type === 'checkboxes') {
                                    // console.log(['GUGU', target, currentDataset, currentDataset[target]])
                                    if(currentDataset[target].includes(value.toString()) ) {
                                        valid = true;
                                    }
                                } else {
                                    if(parseInt(currentDataset[target]) === parseInt(value)) {
                                        valid = true;
                                    }
                                }
                            }
                        }
                    } else {
                        // INITIAL CALL
                        if(targetField.type === 'checkboxes') {
                            if(currentDataset[target].includes(value) ) {
                                valid = true;
                            }
                        } else {
                            if(parseInt(currentDataset[target]) === parseInt(value)) {
                                valid = true;
                            }
                        }
                    }
                } else if(parseInt(condition) === 0) {
                    // "IS NOT" CONDITION
                    if(currentConditionStates !== null) {
                        if(typeof currentConditionStates[target] !== 'undefined') {
                            if(currentConditionStates[target] === true) {
                                if(typeof currentDataset[target] === 'undefined') {
                                    valid = true;
                                } else {
                                    if(targetField.type === 'checkboxes') {
                                        if(currentDataset[target].includes(value) ) {
                                            valid = false;
                                        }
                                    } else {
                                        if(parseInt(currentDataset[target]) === parseInt(value)) {
                                            valid = false;
                                        }
                                    }
                                }
                            }
                        } else {
                            if(typeof currentDataset[target] === 'undefined') {
                                valid = false;
                            } else {
                                if(targetField.type === 'checkboxes') {
                                    if(currentDataset[target].includes(value) ) {
                                        valid = false;
                                    }
                                } else {
                                    if(parseInt(currentDataset[target]) === parseInt(value)) {
                                        valid = false;
                                    }
                                }
                            }

                        }
                    } else {
                        // INITIAL CALL
                        if(typeof currentDataset[target] === 'undefined') {
                            valid = false
                        } else {
                            if(targetField.type === 'checkboxes') {
                                if(!currentDataset[target].includes(value) ) {
                                    valid = false;
                                }
                            } else {
                                if(parseInt(currentDataset[target]) !== parseInt(value)) {
                                    valid = false;
                                }
                            }
                        }
                    }
                }
            });
        }

        return valid;
    }

    const dataChangeHandler = useCallback(async (inputIdentifier, inputValue, isValid) => {

        if(inputValue !== currentDataset[inputIdentifier]) {

            const newDataset = {
                ...currentDataset,
            };

            newDataset[inputIdentifier] = inputValue;
            updateCurrentDataset(newDataset);
            updateSubmitStart(false);
        }
    },[currentDataset]);


    useEffect(() => {
        if(submitStart && datainput.filesCount === datainput.uploadedFilesCount && datainput.error === null && datainput.init) {
            let commitDataset = {...currentDataset};
            fields.forEach((field) => {
                if(field.type === 'file') {
                    delete commitDataset[field.id];
                }
            });

            if(module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 0) {
                if(document.querySelector('#module-terms-condition-checkbox').checked) {
                    commitDataset.termsConditions = 1;
                }
            }

            dispatch(proceedAction(processId, submissionId, module.data.module.id, commitDataset));
        }

    }, [datainput, submitStart]);

    // function handleProceed (event)  {
    function handleProceed (event)  {
        event.preventDefault();

        let valid = true;
        let files = {};
        let commitDataset = {...currentDataset};

        fields.forEach((field) => {

            if(typeof currentConditionStates[field.id] === 'undefined' || currentConditionStates[field.id] === true) {
                if(field.required === 1 && !commitDataset[field.id]) {
                    valid = false;

                } else {
                    if(field.type === 'file') {
                        if(field.required === 1 && typeof commitDataset[field.id].file === 'undefined') {
                            valid = false;
                        } else if(typeof commitDataset[field.id].file !== 'undefined') {
                            files[field.id] = commitDataset[field.id];
                        }
                        delete commitDataset[field.id];

                    } else if(field.type === 'checkboxes') {
                        if(commitDataset[field.id].length) {
                            commitDataset[field.id] = commitDataset[field.id].map(elem=> parseInt(elem));
                        } else {
                            commitDataset[field.id] = [];
                        }
                    } else if(!String(commitDataset[field.id]).length && field.required === 1) {
                        valid = false;
                    }
                }
            } else {
                delete commitDataset[field.id];
            }
        });

        if(module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 0) {
            if(document.querySelector('#module-terms-condition-checkbox').checked) {
                commitDataset.termsConditions = 1;
            } else {
                valid = false;
            }
        }

        if(valid) {
            const fileKeys = Object.keys(files);
            if(fileKeys.length > 0) {
                dispatch({
                    type: SET_FILES_COUNT,
                    count: fileKeys.length,
                });
                fileKeys.forEach(fieldId => {
                    const type = files[fieldId].file.type;
                    const name = files[fieldId].file.name;
                    dispatch(uploadFileAction(processId, submissionId, module.data.module.id, fieldId, type, name, files[fieldId].binary));
                    updateSubmitStart(true);
                });
            } else {

                dispatch(proceedAction(processId, submissionId, module.data.module.id, commitDataset));
            }

        } else {
            toast.error('Die Daten sind nicht vollständig.');
        }

        updateSubmitted(true);
    }

    if(currentConditionStates !== null) {
        return (
            <div id="datainput-component">
                <form className="fields-container" onSubmit={handleProceed} autoComplete="off">
                    {fields.map(
                        (field) => {
                            if(typeof currentConditionStates[field.id] === 'undefined' || currentConditionStates[field.id]) {
                                return DatainputComponents(field.type, {
                                    key: field.id,
                                    id: field.id,
                                    type: field.type,
                                    label: field.heading,
                                    description: field.description,
                                    required: field.required ? 1 : 0,
                                    onInputChange: dataChangeHandler,
                                    options: field.options,
                                    submitted: submitted,
                                    initialValue: currentDataset[field.id],
                                });
                            }
                        }
                    )}

                    {
                        module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 0 &&
                        <TermsInput
                            text={module.data.module.terms_conditions}
                            submitted={submitted}
                        />
                    }
                    <Button primary icon={fatArrowRight} type="submit">{module.data.module.submit_btn_text}</Button>
                </form>
            </div>
        );
    } else {
        return (<Loading/>)
    }
}

export default Datainput