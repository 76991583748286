import {doDownloadRequest, doServerRequest, startLoading, stopLoading} from "./Webservice";
import {toastResponseMessages} from "../../components/ui/Message";

export const DISPLAY_DOWNLOADS = 'DISPLAY_DOWNLOADS';
export const SET_DOWNLOADS = 'SET_DOWNLOADS';
export const SET_CODE_DOWNLOADS = 'SET_CODE_DOWNLOADS';

export const DOWNLOAD_ROUTE = 'pc-api-download/';

// export const displayDownloadAction = () => {
//     return async (dispatch) => {
//         startLoading(dispatch);
//
//         const response = await doServerRequest(DOWNLOAD_ROUTE + 'downloads');
//
//         if (response.status === 'success') {
//
//             dispatch({
//                 type: DISPLAY_DOWNLOADS,
//                 view: 'download',
//                 ...response
//             });
//         } else if(response && response.status === 'error') {
//             toastResponseMessages(response);
//         }
//
//         stopLoading(dispatch, response);
//     };
// };

export const initDownloadsAction = () => {
    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(DOWNLOAD_ROUTE + 'downloads');

        if (response.status === 'success') {
            dispatch({
                type: SET_DOWNLOADS,
                ...response
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const downloadProfileAction = (id, submissionId = false) => {
    return async () => {
        let data = {fileId: id};
        if(submissionId) {
            data.submissionId = submissionId
        }
        const response = await doDownloadRequest(DOWNLOAD_ROUTE + 'profile-download', data);

        if(response && response.status === 'error') {
            toastResponseMessages(response);
        }
    };
};

export const downloadCodeAction = (id, code, submissionId = false, moduleId = false) => {
    return async () => {
        let data = {fileId: id, code: code};
        if(submissionId) {
            data.submissionId = submissionId
        }
        if(moduleId) {
            data.moduleId = moduleId
        }
        const response = await doDownloadRequest(DOWNLOAD_ROUTE + 'download-code', data);

        if(response && response.status === 'error') {
            toastResponseMessages(response);
        }
    };
};

export const downloadModuleAction = (id, submissionId, moduleId, processId) => {
    return async () => {
        let data = {
            fileId: id,
            submissionId: submissionId,
            moduleId: moduleId,
            processId: processId,
        };

        const response = await doDownloadRequest(DOWNLOAD_ROUTE + 'module-download', data);

        if(response && response.status === 'error') {
            toastResponseMessages(response);
        }
    };
};

export const codeDownloadsAction = (code) => {
    return async (dispatch) => {
        const response = await doServerRequest(DOWNLOAD_ROUTE + 'code-downloads',{code: code});

        if(response && response.status === 'error') {
            toastResponseMessages(response);
            dispatch({
                type: SET_CODE_DOWNLOADS,
                downloads: [],
                code: null
            });
        } else {
            if (response.status === 'success') {
                dispatch({
                    type: SET_CODE_DOWNLOADS,
                    ...response,
                    code: code,
                });
            } else if(response && response.status === 'error') {
                toastResponseMessages(response);
            }
        }

    };
};