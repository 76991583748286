import { faChevronUp as fatChevronUp } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import { join } from '../../utility/Css';
import Collapsable from './Collapsable';

export default function CollapsableButtonContainer({
	component = 'div',
	initialCollapsed = false,
	label = '',
	contentContainerClassName,
	contentContainerStyle,
	className,
	children,
	showButton = true,
	...props
}) {
	const [collapsed, setCollapsed] = useState(initialCollapsed);
	const Component = component;

	const onClick = useCallback(() => {
		setCollapsed(prev => !prev);
	}, []);
	
	return (
		<Component
			className={join('collapsable-button-container', collapsed && 'collapsed', className)} {...props}
		>
			<Collapsable collapsed={collapsed}>
				{children}
			</Collapsable>
			{showButton && (
				<button className="collapsable-button" onClick={onClick}>
					<span className="collapsable-button-icon">
						<FontAwesomeIcon icon={fatChevronUp} />
					</span>
					<span className="collapsable-button-label">
						{label}
					</span>
				</button>
			)}
		</Component>
	);
}