import React from "react";

import Text from "../ui/TextInput";
import Textarea from "../ui/TextareaInput";
import Select from "../ui/SelectInput";
import Checkboxes from "../ui/CheckboxesInput";
import Slider from "../ui/SliderInput";
import Grades from "../ui/GradesInput";
import Radio from "../ui/RadioInput";
import File from "../ui/FileInput";
import Note from "../ui/Note";


const Components = {
    text: Text,
    checkboxes: Checkboxes,
    slider: Slider,
    list: Select,
    radio: Radio,
    textarea: Textarea,
    grades: Grades,
    file: File,
    note: Note,
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (type, props) => {

    if (typeof Components[type] !== "undefined") {
        return React.createElement(Components[type], props);
    }

    // component doesn't exist yet
    return React.createElement(
        () => <div>The field {type} has not been created yet.</div>,
        { key: type }
    );
}