import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import DatainputComponents from "../../helpers/DatainputComponent";
import {proceedAction} from "../../../store/actions/Checklist";
import toast from "react-hot-toast";
import TermsInput from "../../ui/TermsInput";
import Button from '../../ui/Button';
import InfoBlock from '../../ui/InfoBlock';
import { faArrowRight as fatArrowRight } from '@fortawesome/pro-thin-svg-icons';

const Checklist = (props) => {

    // console.log('Checklist')

    const dispatch = useDispatch();

    const processId = useSelector(state => state.App.processId);
    const submissionId = useSelector(state => state.App.submissionId);
    const module = useSelector(state => state.App.module);
    const moduleId = useSelector(state => state.App.moduleId);
    const group = useSelector(state => state.App.module.data.group);
    const questions = useSelector(state => state.App.module.data.questions);

    let currentDatasetObj = {};
    questions.forEach((element) => {
        let value;
        switch (element.type) {
            case 'text':
            case 'textarea':
            case 'radio':
            case 'slider':
            case 'grades':
                value = '';
                break;
            case 'checkboxes':
            case 'list':
                value = [];
                break;
            default:
                console.error('TYPE NOT FOUND: ' + element.type)
                value = null;
                break;
        }
        currentDatasetObj[element.id] = value;
    });

    const [currentDataset, updateCurrentDataset] = useState(currentDatasetObj);
    const [submitted, updateSubmitted] = useState(false);

    useEffect(() => {
        updateSubmitted(false);
    }, [group]);

    useEffect(() => {
        if(questions) {
            currentDatasetObj = {};
            questions.forEach((element) => {
                let value;
                switch (element.type) {
                    case 'text':
                    case 'textarea':
                    case 'radio':
                    case 'slider':
                    case 'list':
                    case 'grades':
                        value = '';
                        break;
                    case 'checkboxes':
                        value = '';
                        break;
                    default:
                        alert(element.type)
                        value = 'das';
                        break;
                }
                currentDatasetObj[element.id] = value;
            });
            updateCurrentDataset(currentDatasetObj);
        }

    }, [questions]);

    useEffect(() => {
        updateSubmitted(false);
        updateCurrentDataset(currentDatasetObj);
    }, [moduleId]);

    const dataChangeHandler = useCallback(async (inputIdentifier, inputValue, isValid) => {
        if (inputValue !== currentDataset[inputIdentifier]) {

            const newDataset = {
                ...currentDataset,
            };
            newDataset[inputIdentifier] = inputValue;
            updateCurrentDataset(newDataset);

        }
    }, [currentDataset]);

    function handleProceed(event) {
        event.preventDefault();
        let valid = true;
        questions.forEach((element) => {
            if (element.required === 1 && (!currentDataset[element.id] || (typeof currentDataset[element.id] === 'object' && !currentDataset[element.id].length))) {
                valid = false;
            }
        });

        if (valid) {

            if((module.data.module.display_terms_conditions && module.data.module.terms_conditions)) {

                if(document.querySelector('#module-terms-condition-checkbox').checked) {
                    currentDataset.termsConditions = 1
                    dispatch(proceedAction(processId, submissionId, module.data.module.id, group.id, currentDataset));
                } else {
                    toast.error('Nicht alle erforderlichen Felder ausgefüllt.');
                }

            } else {
                dispatch(proceedAction(processId, submissionId, moduleId, group.id, currentDataset));
            }


        } else {
            toast.error('Die Daten sind nicht vollständig.');
        }

        updateSubmitted(true);
    }

    return (
        <div id="checklist-component">
            <InfoBlock
                headingLevel="2"
                heading={group.heading}
                description={group.description}
            />
            <form className="fields-container" onSubmit={handleProceed} autoComplete="off">
                {questions.map((field) => DatainputComponents(field.type, {
                    key: field.id,
                    id: field.id,
                    type: field.type,
                    label: field.heading,
                    description: field.description,
                    required: field.required ? 1 : 0,
                    onInputChange: dataChangeHandler,
                    options: field.options,
                    submitted: submitted
                }))}

                {
                    module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 0 &&
                    <TermsInput
                        text={module.data.module.terms_conditions}
                        submitted={submitted}
                    />
                }

                <Button primary icon={fatArrowRight} type="submit">{module.data.module.submit_btn_text}</Button>
            </form>
        </div>
    );
}

export default Checklist