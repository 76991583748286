import React from 'react';
import {useSelector} from "react-redux";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';

import CustomerRegistration from "./CustomerRegistration";
import CustomerLogin from "./CustomerLogin";

const Customer = (props) => {
    // console.log('Customer')

    const processId = useSelector(state => state.App.processId);
    const submissionId = useSelector(state => state.App.submissionId);
    const module = useSelector(state => state.App.module);
    const loggedIn = useSelector(state => state.Customer.loggedIn);
    const user = useSelector(state => state.Customer.user);

    return (
        <div id={'customerwrapper'}>
            <Tabs>
                <TabList>
                    <Tab>Registrierung</Tab>
                    {
                        !loggedIn && !user.user_id &&
                        <Tab>Login</Tab>
                    }

                </TabList>

                <TabPanel>
                    <CustomerRegistration
                        processId={processId}
                        submissionId={submissionId}
                        module={module}
                    />
                </TabPanel>
                {
                    !loggedIn && !user.user_id &&
                    <TabPanel>
                        <CustomerLogin
                            processId={processId}
                            submissionId={submissionId}
                            moduleId={module.data.module.id}
                        />
                    </TabPanel>
                }

            </Tabs>
        </div>
    );
}

export default Customer