import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import DatainputComponents from "../../helpers/DatainputComponent";
import {downloadCodeAction, codeDownloadsAction} from "../../../store/actions/Download";
import {
    faArrowDownToLine as fatArrowDownToLine,
    faArrowRight as fatArrowRight,
} from "@fortawesome/pro-thin-svg-icons";
import Button from "../../ui/Button";
import Heading from "../../ui/Heading";


const ProfileDownloadCode = (props) => {

    const dispatch = useDispatch();
    const [code, updateCode] = useState(false);
    const downloads = useSelector(state => state.Download.codeDownloads);
    const currentCode = useSelector(state => state.Download.currentCode);

    const dataChangeHandler = useCallback(async (inputIdentifier, inputValue, isValid) => {
        updateCode(inputValue);
    },[]);

    function codeHandler(event) {
        event.preventDefault();
        dispatch(codeDownloadsAction(code));
    }

    function fileHandler(id) {
        dispatch(downloadCodeAction(id, currentCode));
    }

    return (
        <div>
            <form className="fields-container" onSubmit={codeHandler} autoComplete="off">
                {DatainputComponents('text', {
                    id: 'download-code-input',
                    type: 'text',
                    label: 'Download-Code',
                    description: 'Geben sie hier Ihren Download-Code ein.',
                    required: 1,
                    onInputChange: dataChangeHandler,
                    // submitted: submitted
                })}
                <Button primary icon={fatArrowRight} type="submit">Code absenden</Button>
            </form>

            {
                downloads.length > 0 && currentCode &&
                <div>
                    <Heading level="3" className="download-heading">
                        Für den Code "{currentCode}" verfügbare Dateien:
                    </Heading>

                    {downloads.map((download) => {
                            return (
                                <div className="download" key={download.id}>
                                    <Heading level="4" className="download-heading">
                                        {download.title}
                                    </Heading>
                                    {download.description && <p
                                        className="input-description"
                                        dangerouslySetInnerHTML={{ __html: download.description }}
                                    />}
                                    <Button
                                        className="download-button"
                                        primary
                                        icon={fatArrowDownToLine}
                                        onClick={() => fileHandler(download.id)}
                                    >
                                        Herunterladen
                                    </Button>
                                </div>
                            );
                        }
                    )}
                </div>
            }
        </div>
    );
}

export default ProfileDownloadCode