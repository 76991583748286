import React, { useMemo } from 'react';
import {useSelector} from "react-redux";

const Loading = () => {

    const loading = !!useSelector(state => state.App.loading);

    return useMemo(() => (
        loading && (
            <div id="loading-wrapper"></div>
        )
    ), [loading]);
}

export default Loading;