import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import DatainputComponents from "../../helpers/DatainputComponent";
import {proceedAction} from "../../../store/actions/Seminar";
import toast from "react-hot-toast";
import Moment from 'moment';
import TermsInput from "../../ui/TermsInput";
import Button from '../../ui/Button';
import { faArrowRight as fatArrowRight } from '@fortawesome/pro-thin-svg-icons';
import InfoBlock from '../../ui/InfoBlock';
import Heading from '../../ui/Heading';

const Seminar = (props) => {
    // console.log('Seminar')

    const dispatch = useDispatch();

    const processId = useSelector(state => state.App.processId);
    const submissionId = useSelector(state => state.App.submissionId);
    const module = useSelector(state => state.App.module);
    const moduleId = useSelector(state => state.App.moduleId);

    const [currentValue, updateCurrentValue] = useState(module.data.seminars.length === 1 ? module.data.seminars[0].value : null);
    const [currentDates, updateCurrentDates] = useState(module.data.seminars.length === 1 ? module.data.dates[module.data.seminars[0].value] : null);
    const [submitted, updateSubmitted] = useState(false);

    useEffect(() => {
        updateCurrentValue(null);
        updateCurrentDates(null);
        updateSubmitted(false);
    }, [moduleId, module]);

    const dataChangeHandler = useCallback(async (inputIdentifier, inputValue, isValid) => {
        if (typeof module.data.dates[currentValue] !== 'undefined') {
            updateCurrentDates(module.data.dates[currentValue]);
        }

        if (inputValue !== currentValue) {
            updateCurrentValue(inputValue);
        }
    }, [currentValue, module.data.dates]);

    function handleProceed() {
        if (currentValue) {
            if((module.data.module.display_terms_conditions && module.data.module.terms_conditions)) {

                if(document.querySelector('#module-terms-condition-checkbox').checked) {
                    dispatch(proceedAction(processId, submissionId, module.data.module.id, {seminar: currentValue, termsConditions: 1}));
                } else {
                    toast.error('Nicht alle erforderlichen Felder ausgefüllt.');
                }

            } else {
                dispatch(proceedAction(processId, submissionId, module.data.module.id, {seminar: currentValue}));
            }

        } else {
            toast.error('Bitte wählen Sie ein Seminar.');
        }

        updateSubmitted(true);
    }

    const contingentHtml = (module.data.type.contingent ? `<p>Teilnehmeranzahl: ${module.data.type.contingent}</p>` : '');
    const description = (module.data.type.description ?? '') + contingentHtml;

    return (
        <div id={'modulewrapper'}>
            <InfoBlock
                headingLevel="2"
                heading={module.data.type.heading}
                description={description}
            />

            {
                module.data.seminars.length ?
                    <div className="fields-container">

                        {DatainputComponents('list', {
                            id: 'seminar-select',
                            type: 'list',
                            label: 'Seminarauswahl',
                            // description: 'Seminarauswahl DESC',
                            required: 1,
                            onInputChange: dataChangeHandler,
                            options: module.data.seminars,
                            submitted: submitted,
                            initialTouched: true,
                            initialValue: module.data.seminars.length === 1 ? module.data.seminars[0].value : false
                        })}

                        {
                            currentDates &&
                            <div>
                                <Heading level={3}>Termine</Heading>
                                <ul>
                                    {currentDates.map((option) => {
                                            return (
                                                <li key={option.id}>
                                                    <span>{Moment(option.date).format("DD.MM.YYYY")}</span> | <span>{Moment('2000-01-01 ' + option.beginn).format("HH:mm")} - {Moment('2000-01-01 ' + option.end).format("HH:mm")}</span>
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            </div>
                        }
                        {
                            module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 0 &&
                            <TermsInput
                                text={module.data.module.terms_conditions}
                                submitted={submitted}
                            />
                        }
                        {
                            !submitted &&
                            <Button primary icon={fatArrowRight} onClick={handleProceed}>{module.data.module.submit_btn_text}</Button>
                        }

                    </div>
                    :
                    <div>
                        <span><b>Leider sind derzeit keine Seminare verfügbar. Versuchen Sie es später erneut.</b></span>
                    </div>
            }
        </div>
    );
}

export default Seminar