import {doServerRequest, startLoading, stopLoading} from "./Webservice";
import {PROCESS} from "./App";
import {toastResponseMessages} from "../../components/ui/Message";

export const SEMINAR_ROUTE = 'pc-api-seminar/';

export const proceedAction = (processId, submissionId, moduleId, data) => {
    return async (dispatch) => {
        startLoading(dispatch);

        data.processId = processId
        data.submissionId = submissionId
        data.moduleId = moduleId

        const response = await doServerRequest(SEMINAR_ROUTE + 'submit', data);

        if (response.status === 'success') {
            dispatch({
                type: PROCESS,
                ...response
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};