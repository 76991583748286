import { join } from '../../utility/Css';

const Heading = ({ level = 1, noMargin = false, className, ...props }) => {
	const Component = 'h' + level;
	
	return (
		<Component className={join(noMargin && 'no-margin', className)} {...props} />
	);
}

export default Heading;