import React from 'react';
import { Provider } from 'react-redux'
import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import {BrowserRouter as Router,} from "react-router-dom";

import appReducer from "./store/reducers/App";
import customerReducer from "./store/reducers/Customer";
import downloadReducer from "./store/reducers/Download";
import datainputReducer from "./store/reducers/Datainput";
import evaluationReducer from "./store/reducers/Evaluation";

import Process from "./screen/Process";
import AppInfo from "./screen/AppInfo";

const wrapReducer = combineReducers({
  App: appReducer,
  Customer: customerReducer,
  Download: downloadReducer,
  Datainput: datainputReducer,
  Evaluation: evaluationReducer,
});

const rootReducer = (state, action) => {
  return wrapReducer(state, action)
};

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

function App() {

    return (
        <Provider store={store}>
            <Router>
                <Process/>
                <AppInfo/>
            </Router>
        </Provider>
  );
}

export default App;