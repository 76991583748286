
/**
 * @param  {...string} cssClasses 
 * @returns {string}
 */
export const join = (...cssClasses) => {
    let result = cssClasses[0] || '';

    for (let i = 1; i < cssClasses.length; i++) {
        const cssClass = cssClasses[i];

        if (cssClass) {
            result += ' ' + cssClass;
        }
    }

    return result;
}