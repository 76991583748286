import {SET_FILES_COUNT, UPLOAD_ERROR, UPLOAD_SUCCESS} from "../actions/Datainput";
import {PROCESS} from "../actions/App";

const initialState = {
    filesCount: 0,
    uploadedFilesCount: 0,
    error: null,
    init: false,
}

// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILES_COUNT:
            return {
                uploadedFilesCount: 0,
                filesCount: action.count,
                error: null,
                init: true,
            };
        case UPLOAD_SUCCESS:
            return {
                ...state,
                uploadedFilesCount: (state.uploadedFilesCount + 1),
            };
        case UPLOAD_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case PROCESS:
            return {
                filesCount: 0,
                uploadedFilesCount: 0,
                error: null,
                init: false,
            }
        default:
            return state
    }
};