import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {downloadProfileAction, initDownloadsAction} from "../../../store/actions/Download";
import Heading from "../../ui/Heading";
import Button from "../../ui/Button";
import {faArrowDownToLine as fatArrowDownToLine} from "@fortawesome/pro-thin-svg-icons";


const ProfileDownloadCustomer = (props) => {

    const dispatch = useDispatch();
    const downloads = useSelector(state => state.Download.downloads);
    const downloadsReady = useSelector(state => state.Download.loaded);

    const [downloadLoaded, updateDownloadsLoaded] = useState(false);

    useEffect(() => {
        if(downloadsReady === false && !downloadLoaded) {
            updateDownloadsLoaded(true);
            dispatch(initDownloadsAction());
        }

    },[dispatch, downloadsReady, downloadLoaded]);

    function downloadHandler(id) {
        dispatch(downloadProfileAction(id));
    }

    return (
        downloads.length > 0 ?
            <div>
                {downloads.map((download) => {
                        return (
                            <div className="download" key={download.id}>
                                <Heading level="4" className="download-heading">
                                    {download.title}
                                </Heading>
                                {download.description && <p
                                    className="input-description"
                                    dangerouslySetInnerHTML={{ __html: download.description }}
                                />}
                                <Button
                                    className="download-button"
                                    primary
                                    icon={fatArrowDownToLine}
                                    onClick={() => downloadHandler(download.id)}
                                >
                                    Herunterladen
                                </Button>
                            </div>
                        );
                    }
                )}
            </div>
        :
            <div>
                <b>Derzeit stehen Ihnen keine Dateien zur Verfügung. Versuchen Sie es später erneut.</b>
            </div>
    );
}

export default ProfileDownloadCustomer