export const CUSTOMER_FIELD_MAP = [
    'id',
    'user_id',
    'email',
    'branch',
    'company',
    'department',
    'salutation',
    'title',
    'firstname',
    'lastname',
    'street',
    'zip',
    'city',
    'state',
    'phone',
    'fax',
    'mobile',
    'website',
    'facebook',
    'linkedin',
    'xing',
    'twitter',
    'instagram',
    'tiktok',
    'require_delete',
];

export class Customer {

    constructor(data) {
        if(data) {
            CUSTOMER_FIELD_MAP.forEach(ele => {
                this[ele] = typeof data[ele] !== 'undefined' ? data[ele] : null;
            });
        }
    }
}
