import {
    BRANCHES_LOADED, CANCEL_DELETE_ACCOUNT, DELETE_ACCOUNT, EMAIL_CONFIRMED, INIT_CUSTOMER, LOGOUT_CUSTOMER,
    MODULE_LOGIN, PASSWORD_RESET, PROFILE_LOGIN, SALUTATIONS_LOADED, VIEW_FORGOT
} from "../actions/Customer";
import {PROCEED} from "../actions/App";
import {Customer} from "../../components/Customer";

// let test = new Customer({});
// console.log(test);
const initialState = {
    loggedIn: false,
    user: new Customer({}),
    token: null,
    salutations: [],
    branches: [],
}

// eslint-disable-next-line
export default (state = initialState, action) => {
    let newState = {};
    let user
    const newCustomer = new Customer(action.user ? action.user : {});
    const loggedIn = !!newCustomer.user_id;
    // console.log('action.type', action.type, action, state.user)
    switch (action.type) {
        case INIT_CUSTOMER:
            return {
                ...state,
                user: newCustomer,
                loggedIn: loggedIn,
            };

        case PROCEED:
            newState = state;
            if(action.user) {
                newState.user = action.user;
            }

            return {
                ...state,
                ...newState
            };

        case MODULE_LOGIN:
        case PROFILE_LOGIN:
            return {
                ...state,
                user: newCustomer,
                loggedIn: loggedIn,

            };

        case LOGOUT_CUSTOMER:
            return {
                ...state,
                user: new Customer({}),
                loggedId: false,
            };

        case VIEW_FORGOT:
            return {
                ...state,
                token: action.token
            }

        case PASSWORD_RESET:
            return {
                ...state,
                token: null
            }

        case EMAIL_CONFIRMED:
            return {
                ...state,
                token: null,
                user: new Customer(action.user ? action.user : {}),
            }

        case SALUTATIONS_LOADED:
            return {
                ...state,
                salutations: action.salutations
            }

        case BRANCHES_LOADED:
            return {
                ...state,
                branches: action.branches
            }

        case DELETE_ACCOUNT:
            user = state.user;
            user.require_delete = 1;

            return {
                ...state,
                user: user
            }

        case CANCEL_DELETE_ACCOUNT:
            user = state.user;
            user.require_delete = 0;

            return {
                ...state,
                user: user
            }

        default:
            return state
    }
};