import PcConfig from '../../utility/PcConfig';
import {END_LOADING, START_LOADING} from "./App";

class WebserviceError extends Error {
    constructor(message) {
        super(message);
        this.name = "WebserviceError";
    }
}

const { SERVER_URL, AUTH, DECODED } = PcConfig;

const DEFAULT_OPTIONS = {
    method: 'POST',
    mode: "cors",
    credentials: 'include',
    headers: {
        'Authorization': 'Basic ' + AUTH,
        'X-Requested-With': 'XMLHttpRequest'
    },
};

const REQUEST_OPTIONS = {
    ...DEFAULT_OPTIONS,
    headers: {
        ...DEFAULT_OPTIONS.headers,
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};

const DOWNLOAD_OPTIONS = {
    ...DEFAULT_OPTIONS,
};

const UPLOAD_OPTIONS = {
    ...DEFAULT_OPTIONS,
};

export async function doServerRequest(url, params) {
    try {
        const currentOption = getCurrentOptions(REQUEST_OPTIONS, params);
        const response = await getResponse(url, currentOption);
        return await getReturn(response);
    } catch(err) {
        if(err instanceof WebserviceError) {
            return {status: 'error', msg: err.message};
        }
        console.error(err)
        return {status: 'error', msg: "Systemfehler. Bitte laden Sie die Seite neu und versuchen es erneut. Wenn Sie weiterhin Probleme haben, kontaktieren Sie den Seitenbetreiber. (Fehlercode: 13A37)"};
    }
}

export async function doUploadRequest(url, params, svgBlob) {
    try {
        const currentOption = {...UPLOAD_OPTIONS};
        currentOption.body = svgBlob;
        url = url + '?' + new URLSearchParams(getParams(params)).toString();
        const response = await getResponse(url, currentOption);
        return await getReturn(response);
    } catch(err) {
        // HERE WE ARE
        if(err instanceof WebserviceError) {
            return {status: 'error', msg: err.message};
        }
        return {status: 'error', msg: "Systemfehler. Bitte laden Sie die Seite neu und versuchen es erneut. Wenn Sie weiterhin Probleme haben, kontaktieren Sie den Seitenbetreiber. (Fehlercode: 13B37)"};
    }
}

export async function doDownloadRequest(url, params) {
    try {
        const currentOption = getCurrentOptions(DOWNLOAD_OPTIONS, params, false, true);
        const response = await getResponse(url, currentOption);
        const filename = response.headers.get('pc-filename');
        const blob = await response.blob();
        const clickUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = clickUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return {status: 'success'};
    } catch(err) {
        if(err instanceof WebserviceError) {
            return {status: 'error', msg: err.message};
        }
        return {status: 'error', msg: "Systemfehler. Bitte laden Sie die Seite neu und versuchen es erneut. Wenn Sie weiterhin Probleme haben, kontaktieren Sie den Seitenbetreiber. (Fehlercode: 13C37)"};
    }
}


async function getResponse(url, currentOption) {

    const response = await Promise.race(getPromis(url, currentOption));

    if (!response.ok) {
        throw new WebserviceError('Kommunikationsfehler. Bitte versuchen Sie es erneut. Wenn Sie weiterhin Probleme haben, kontaktieren Sie den Seitenbetreiber. (Fehlercode: 47A11)')
    }

    if(response.status === 'error') {
        throw new WebserviceError('Systemfehler. Bitte versuchen Sie es erneut. Wenn Sie weiterhin Probleme haben, kontaktieren Sie den Seitenbetreiber. (Fehlercode: 47B11)')
    }

    return response;
}

async function getReturn(response) {
    let res = await response.json();

    if(DECODED) {
        res = decodeObject(res);
    }

    return res;
}

function getCurrentOptions(defaultOptions, params, isUploadRequest = false, isDownloadRequest = false) {
    const options = {...defaultOptions};
    if(typeof params !== 'undefined') {
        if(!isUploadRequest) {
            if(DECODED) {
                params = encodeObject(params);
                options.body = new URLSearchParams(params);
            } else {
                if(!isDownloadRequest) {
                    options.body = prepareNotEncodedBody(params);
                } else {
                    options.body = prepareNotEncodedBody(params, true);
                }
            }
        }
    }
    return options;
}


function getPromis(url, currentOption)
{
    return [
        fetch(SERVER_URL + url, currentOption),
        new Promise((_, reject) => setTimeout(() => reject(new Error('TIMEOUT')), 10000))
    ]
}

function encodeObject (obj, parentKey = null) {

    let response = {};
    Object.keys(obj).forEach(key => {
        if(typeof obj[key] === 'object' && obj[key] !== null) {
            response = {
                ...response,
                ...encodeObject(obj[key], key)
            }
        } else {
            if(obj[key] === null) {
                if(parentKey !== null) {
                    response[btoa(parentKey) + '['+btoa(key)+']'] = null;
                } else {
                    response[btoa(key)] = null;
                }

            } else if(typeof obj[key] == "boolean") {
                if(parentKey !== null) {
                    response[btoa(parentKey) + '['+btoa(key)+']'] = obj[key];
                } else {
                    response[btoa(key)] = obj[key];
                }
            } else {
                let newValue = b64EncodeUnicode(obj[key]);

                if(parentKey !== null) {
                    response[btoa(parentKey) + '['+btoa(key)+']'] =newValue;
                } else {
                    response[btoa(key)] = newValue;
                }
            }
        }
    });

    return response;
}

function prepareNotEncodedBody (obj, asObject = false) {

    const params = new URLSearchParams()

    Object.entries(obj).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((value, i) => params.append(key + '[' + i + ']', value.toString()))
        // } else if(typeof value == "boolean") {
        //     params.append(key, value)
        } else if(value === null) {
            params.append(key, null)
        } else {
            params.append(key, value.toString())
        }
    });

    return asObject ? params : params.toString();
}

function decodeObject (obj, isArray = false) {

    let response;
    if(Array.isArray(obj)) {
        isArray = true;
        response = [];
    } else {
        response = {};
    }

    Object.keys(obj).forEach(key => {
        // console.log(key, obj);
        if(typeof obj[key] === 'object' && obj[key] !== null) {
            if(isArray) {
                response.push(decodeObject(obj[key]));
            } else {
                response[atob(key)] = decodeObject(obj[key]);
            }

        } else {
            if(typeof obj[key] == "boolean") {
                response[atob(key)] = obj[key];
            } else if(obj[key] === null) {
                response[atob(key)] = null;
            } else if(obj[key] === "") {
                response[atob(key)] = "";
            } else {
                let newValue = b64DecodeUnicode(obj[key]);
                if(!isNaN(newValue)) {
                    newValue = +newValue;
                }
                response[atob(key)] = newValue;
            }
        }
    });

    if(Array.isArray(obj)) {
        return Object.values(response);
    } else {
        return response;
    }
}

function getParams(params)
{
    if(DECODED) {
        // console.log('SEND BEFORE', url, params);
        params = encodeObject(params);
        // console.log('SEND AFTER', url, encodedParams);
    }

    return params
}

function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

export function b64DecodeUnicode(str) {
    let test  =  atob(str).split('')
        .map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('');
    return decodeURIComponent(test);
}

export function startLoading(dispatch) {
    dispatch({
        type: START_LOADING
    });
}

export function stopLoading(dispatch, response) {
    if(response) {
        dispatch({
            type: END_LOADING,
        });
    }
}
