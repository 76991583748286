
import { join } from '../../utility/Css';

const Table = ({ className, children, ...props }) => {
    return (
        <div className={join('table', className)} {...props}>
            <table className="table-element">
                {children}
            </table>
        </div>
    );
}

Table.Row = ({ className, ...props }) => {
    return (
        <tr className={join('table-row', className)} {...props} />
    );
}

Table.Cell = ({ className, ...props }) => {
    return (
        <td className={join('table-cell', className)} {...props} />
    );
}

Table.Head = ({ className, ...props }) => {
    return (
        <thead className={join('table-head', className)} {...props} />
    );
}

Table.Body = ({ className, ...props }) => {
    return (
        <tbody className={join('table-body', className)} {...props} />
    );
}

Table.Foot = ({ className, ...props }) => {
    return (
        <tfoot className={join('table-foot', className)} {...props} />
    );
}

export default Table;