import React from 'react';
import NextButton from '../../ui/NextButton';
import InfoBlock from '../../ui/InfoBlock';

const BranchNext = (props) => {
    // console.log('BranchNext')

    const handler = props.handler;
    const branch = props.branch;

    return (
        <div className="branch">
            <InfoBlock
                headingLevel="2"
                heading={branch.heading}
                description={branch.description}
            />
            <NextButton onClick={() => {handler(branch.id);}}>{branch.button_text}</NextButton>
        </div>
    );


}

export default BranchNext