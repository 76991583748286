import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {SET_SUCCESS} from "../../store/actions/App";
import InfoBlock from '../ui/InfoBlock';
import ProcessBtn from "../ui/ProcessBtn";

const Success = () => {
    // console.log('Success')

    const dispatch = useDispatch();

    const success = useSelector(state => state.App.success);
    const processable = useSelector(state => state.App.processable);

    function handleProceed ()  {
        dispatch({type: SET_SUCCESS});
    }

    return (
        <>
            <InfoBlock heading={success.heading} description={success.description} />
            <ProcessBtn handleProceed={handleProceed} isSuccess={true}/>
        </>
    );
}

export default Success