import React from 'react';
import packageJson from '../../package.json';

const AppInfo = () => {
// console.log('AppInfo')

    return (
        <div className="app-info">
            <span>Copyright&nbsp;&copy;&nbsp;{new Date().getFullYear()}</span>&nbsp;
            <a href={'https://www.toolstage.de'} target={'_blank'} rel={'noreferrer'}>{packageJson.name}</a>&nbsp;
            <span>v{packageJson.version}</span>&nbsp;
            <span>All&nbsp;rights&nbsp;reserved.</span>
        </div>
    );
};

export default AppInfo;