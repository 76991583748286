import { faFileArrowUp as fatFileArrowUp } from '@fortawesome/pro-thin-svg-icons';
import React, {useReducer, useEffect, useRef} from 'react';
import Button from './Button';

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {

    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid,
                touched: true
            };
        case INPUT_BLUR:
            return {
                ...state,
                touched: true
            };
        default:
            return state;
    }
};

const FileInput = props => {

    const inputRef = useRef();
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: (props.initialValue ? props.initialValue : ''),
        isValid: ((props.required === 1 && props.initialValue) || props.required === 0),
        touched: false
    });

    // const [errorIcon, setErrorIcon] = useState('');
    // const [errorIconColor, setErrorIconColor] = useState('');
    const {onInputChange, id} = props;

    useEffect(() => {
        if (inputState.touched) {
            onInputChange(id, inputState.value, inputState.isValid);
        }

        if (inputState.isValid === false && inputState.touched) {
            // DoToast('danger', props.errorText);
            // setErrorIcon('close-circle');
            // setErrorIconColor(Colors.alarmColor);
        }
        if (inputState.isValid && inputState.touched) {
            // setErrorIcon('checkmark-circle');
            // setErrorIconColor(Colors.secondaryColor);
        }
    }, [inputState, onInputChange, id]);

    const fileChangeHandler = event => {
        const file = event.target.files[0];

        let isValid = true;
        if (props.required === 1 && !file) {
            isValid = false;
        }

        const reader = new FileReader();
        reader.onload = function (event) {

            const value = {};
            value.file = file;
            value.binary = event.target.result;

            dispatch({type: INPUT_CHANGE, value: value, isValid: isValid, touched: isValid});
        };
        reader.readAsDataURL(file);
    };

    const clickInput = () => {
        inputRef.current?.click();
    }

    return (
        <div className={'input input-file' + ((inputState.isValid === false || typeof inputState.isValid === 'undefined') && (inputState.touched || props.submitted) ? 'error' : '')}>
            <label className="input-label">{props.label} {props.required === 1 && '*'}</label>
            <input
                ref={inputRef}
                className="visually-hidden"
                // accept=".doc, .docx, .pdf, .xls, .xlsx, .odt, .ods, .txt"
                type={props.type}
                required={props.required === 1}
                onChange={fileChangeHandler}
            />
            <Button
                className="input-file-button"
                primary
                icon={fatFileArrowUp}
                onClick={clickInput}
            >
                {inputRef.current?.files[0]?.name ?? 'Datei auswählen'}
            </Button>
            {
                props.description &&
                <p
                    className="input-description"
                    dangerouslySetInnerHTML={{__html: props.description}}
                />
            }
        </div>
    );
};

export default FileInput;
