import {doServerRequest, doUploadRequest, startLoading, stopLoading} from "./Webservice";
import {PROCESS} from "./App";
import {toastResponseMessages} from "../../components/ui/Message";

export const DATAINPUT_ROUTE = 'pc-api-datainput/';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const SET_FILES_COUNT = 'SET_FILES_COUNT';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';

export const proceedAction = (processId, submissionId, moduleId, data) => {
    return async (dispatch) => {
        startLoading(dispatch);

        data.processId = processId
        data.submissionId = submissionId
        data.moduleId = moduleId

        const response = await doServerRequest(DATAINPUT_ROUTE + 'submit', data);

        if (response && response.status === 'success') {
            dispatch({
                type: PROCESS,
                ...response
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const uploadFileAction = (processId, submissionId, moduleId, fieldId, type, name, file) => {
    return async (dispatch) => {
        startLoading(dispatch);

        const data = {};
        data.processId = processId;
        data.submissionId = submissionId;
        data.moduleId = moduleId;
        data.fieldId = fieldId;
        data.type = type;
        data.name = name;
        // let params = new URLSearchParams(data).toString();

        const response = await doUploadRequest(DATAINPUT_ROUTE + 'upload-file', data, file);

        if (response && response.status === 'success') {
            dispatch({
                type: UPLOAD_SUCCESS,
                ...response
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
            dispatch({
                type: UPLOAD_ERROR,
                error: true,
            });
        }

        stopLoading(dispatch, response);
    };
};