import React from 'react';
import toast, {Toaster} from "react-hot-toast";

const Message = () => {
    // console.log('Message')

    return (
        <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                    background: '#363636',
                    color: '#fff',
                },
                // Default options for specific types
                success: {
                    duration: 3000,
                    theme: {
                        primary: 'green',
                        secondary: 'black',
                    },
                },
            }}
        />
    );
}

export function toastResponseMessages(response) {

    if(typeof response.msg === 'string') {
        toast.error(response.msg);
    } else if(typeof response.msg === 'object') {
        Object.keys(response.msg).forEach(function(index) {
            if(typeof response.msg[index] === 'string') {
                toast.error(response.msg[index]);
            } else if(typeof response.msg[index] === 'object') {
                Object.keys(response.msg[index]).forEach(function (msg) {
                    toast.error(response.msg[index][msg]);
                });
            }
        });
    }

    if(Array.isArray(response.errors)) {
        response.errors.reverse().forEach(err => {
            if(typeof err === 'string') {
                toast.error(err);
            }
        })
    }
}

export default Message;