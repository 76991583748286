import React from 'react';
import {useSelector} from "react-redux";
import Components from "../helpers/Component";
import Tool from "../ui/Tool";
import ErrorPage from "../ui/ErrorPage";
import InfoBlock from '../ui/InfoBlock';
import usePreviousRender from '../../hooks/usePreviousRender';

const Module = (props) => {

    // console.log('MODULE')
    
    const success = useSelector(state => state.App.success);
    const view = useSelector(state => state.App.view);
    const profileView = useSelector(state => state.App.profileView);
    const token = useSelector(state => state.Customer.token);
    const module = useSelector(state => state.App.module);

    const moduleId = module?.data?.module?.id;
    const previousModuleId = usePreviousRender(moduleId, moduleId);

    if(profileView) {

        if(token) {
            return (
                <div className="component">
                    {Components('reset')}
                </div>
            );
        } else {
            return (
                <div className="component">
                    {Components(view)}
                </div>
            );
        }

    } else if(success) {

        return (
            <div className="component">
                {Components('success')}
            </div>
        );

    } else if(view === 'tool') {
        return (
            <div className="component">
                <Tool />
            </div>
        )
    }  else if(view === 'error') {
        return (
            <div className="component">
                <ErrorPage />
            </div>
        )
    } else if(module) {
        if (previousModuleId !== moduleId) {
            document.body?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }

        return (
            <div className="component">
                <InfoBlock
                    heading={module.data.module.heading}
                    description={module.data.module.description}
                />
                {Components(view)}
            </div>
        );

    } else {
        return null;
        // return (
        //     <div>
        //         <div>LOADING</div>
        //     </div>
        // );
    }
}

export default Module