import { join } from '../../utility/Css';
import Heading from './Heading';

const InfoBlock = ({ heading, headingLevel = 1, description, className, ...props }) => {
	if (!heading && !description) return null;

	return (
		<div className={join('info-block', className)} {...props}>
			{!!heading && <Heading level={headingLevel}>{heading}</Heading>}
			{!!description && <div
				className="info-block-description"
				dangerouslySetInnerHTML={{ __html: description }}
			/>}
		</div>
	);
}

export default InfoBlock;