import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    continueProcessAction,
    initToolAction,
    initProcessAction,
    processAction, SET_TARGET_DOMAIN, DEFAULT_INIT, UPDATE_PROCESS_ID
} from "../store/actions/App";
import Module from "../components/module/Module";
import User from "../components/ui/User";
import Message from "../components/ui/Message";
import {approvedProcessAction, emailConfirmedAction, passwordResetAction} from "../store/actions/Customer";
import Loading from "../components/ui/Loading";
import {Helmet} from 'react-helmet';
import {colorGetValues} from '../utility/Color';
import {useSearchParams} from "react-router-dom";

// import {withNamespaces} from "react-i18next";

const Screen = (props) => {
// console.log('SCREEN')
    const dispatch = useDispatch();

    const userInit = useSelector(state => state.App.userInit);
    const globalProcessId = useSelector(state => state.App.processId);
    const processInit = useSelector(state => state.App.processInit);
    const defaultInit = useSelector(state => state.App.defaultInit);
    const targetDomain = useSelector(state => state.App.targetDomain);
    const user = useSelector(state => state.Customer.user);
    const processable = useSelector(state => state.App.processable);
    const success = useSelector(state => state.App.success);
    const submissionId = useSelector(state => state.App.submissionId);
    const moduleId = useSelector(state => state.App.moduleId);
    const style = useSelector(state => state.App.style);
    const refresh = useSelector(state => state.App.refresh);

    // const [initFinished, updateInitFinished] = useState(false);
    const [confirmedSend, updateConfirmedSend] = useState(false);
    const [listenerAdded, updateListenerAdded] = useState(false);
    const [approvedSend, updateApprovedSend] = useState(false);
    const [tokenSend, updateTokenSend] = useState(false);
    const [proceedSend, updateProceedSend] = useState(false);
    const [lastSize, updateLastSize] = useState(0);

    const processId = props.processId;
    const toolId = props.toolId;
    const preview = props.preview;

    /** TEST LOCAL @TODO **/
    const [searchParams] = useSearchParams();
    let tsemailconfirmed = searchParams.get("tsemailconfirmed");
    let tsprocess = searchParams.get("tsprocess");
    let approved = searchParams.get("approved");
    let token = searchParams.get("token");

    const styleCssString = useMemo(() => {
        if (!style) return '';

        const cssColor = serverString => {
            if (!serverString) return '0 0 0';

            const {r, g, b} = colorGetValues(serverString);

            return `${r} ${g} ${b}`;
        }

        return `
            :root {
                ${style['font-family'] && `--font-family-body: ${style['font-family']};`}
                ${style['font-size'] && `--font-size-body: ${style['font-size']}px;`}
                ${style['font-color'] && `--color-body-font: ${cssColor(style['font-color'])};`}
                ${style['primary-color'] && `--color-primary: ${cssColor(style['primary-color'])};`}
                ${style['secondary-color'] && `--color-secondary: ${cssColor(style['secondary-color'])};`}
            }
        `;
    }, [style]);

    // LOCAL DEVELOPMENT AND PREVIEW BUTTON
    useEffect(() => {
        if (preview && userInit) {
            dispatch({type: DEFAULT_INIT})
        }
    }, [userInit, preview]);

    useEffect(() => {
        if(targetDomain && refresh === true) {
            setTimeout(function() {
                window.parent.postMessage({refresh: true}, targetDomain);
            }, 1500);
        }
    }, [refresh, targetDomain]);

    useEffect(() => {
        if (!globalProcessId && processId) {
            dispatch({type: UPDATE_PROCESS_ID, processId: processId})
            // updateDefaultInit(true);
        }
    }, [processId, globalProcessId]);

    if (targetDomain) {
        // updateResizeInit(true);
        const rIframe = {};
        rIframe.requestAnimFrame = (function () {
            return window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.oRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                function (callback) {
                    window.setTimeout(callback, 1000 / 6);
                };
        })();

        rIframe.windowHeight = 0;
        rIframe.htmlElement = document.getElementsByTagName('html')[0];
        rIframe.resizeFrame = function () {

            var windowHeight = document.body ? Math.max(document.body.offsetHeight, rIframe.htmlElement.offsetHeight) : rIframe.htmlElement.offsetHeight;
            if (windowHeight > 0) {
                if (rIframe.windowHeight === windowHeight) {
                    rIframe.requestAnimFrame.call(window, rIframe.resizeFrame);
                    return false;
                }

                if (windowHeight !== lastSize && (processInit || tokenSend || confirmedSend || approvedSend)) {
                    updateLastSize(windowHeight);
                    rIframe.windowHeight = windowHeight;
                    window.parent.postMessage({resize: windowHeight}, targetDomain);
                    rIframe.requestAnimFrame.call(window, rIframe.resizeFrame);
                }
            }
        };

        rIframe.requestAnimFrame.call(window, rIframe.resizeFrame);
    }

    useEffect(() => {

        if (targetDomain) {
            window.parent.postMessage({initCall: true}, targetDomain);
        } else {
            dispatch({
                type: SET_TARGET_DOMAIN,
                targetDomain: ((window.location !== window.parent.location)
                        ? (document.referrer.charAt(document.referrer.length - 1) === '/') ? document.referrer.slice(0, -1) : document.referrer
                        : (document.location.href.charAt(document.location.href.length - 1) === '/') ? document.location.href.slice(0, -1) : document.location.href
                )
            });
        }
    }, [targetDomain]);

    useEffect(() => {
        if (userInit && defaultInit && !processInit) {
            if(tsemailconfirmed) {
                updateConfirmedSend(true);
                dispatch(emailConfirmedAction(processId, submissionId, {tsemailconfirmed: tsemailconfirmed}, specialInitErrorHandler));
            } else if (tsprocess) {
                updateProceedSend(true);
                dispatch(continueProcessAction(processId, {tsprocess: tsprocess}, specialInitErrorHandler));
            } else if (approved) {
                updateApprovedSend(true);
                dispatch(approvedProcessAction(processId, {approved: approved}, specialInitErrorHandler));
            } else if (token) {
                updateTokenSend(true);
                dispatch(passwordResetAction(processId, {token: token}, specialInitErrorHandler));
            } else if (processId) {
                initProcess();
            } else if (toolId) {
                initTool();
            }
        }
    }, [processInit, userInit, defaultInit, processId, toolId, tsemailconfirmed, tsprocess, approved, token]);

    useEffect(() => {
        if(!listenerAdded && targetDomain) {
            updateListenerAdded(true);
            if (window.addEventListener) {
                window.addEventListener("message", onMessage, false);
            } else if (window.attachEvent) {
                window.attachEvent("onmessage", onMessage, false);
            }
        }
    }, [listenerAdded, targetDomain]);

    function onMessage(event) {

        const origin = new URL(event.origin);
        const target = new URL(targetDomain);
        if ((origin.hostname === target.hostname)) {

            if (typeof event.data.tsprocess !== 'undefined' && !proceedSend) {
                updateProceedSend(true);
                dispatch(continueProcessAction(processId, event.data, specialInitErrorHandler));
            } else if (typeof event.data.approved !== 'undefined' && !approvedSend) {
                updateApprovedSend(true);
                dispatch(approvedProcessAction(processId, event.data, specialInitErrorHandler));
            } else if (typeof event.data.token !== 'undefined' && !tokenSend) {
                updateTokenSend(true);
                dispatch(passwordResetAction(processId, {token: event.data.token}, specialInitErrorHandler));
            } else if (typeof event.data.tsemailconfirmed !== 'undefined' && !confirmedSend) {
                updateConfirmedSend(true);
                dispatch(emailConfirmedAction(processId, submissionId, event.data, specialInitErrorHandler));
            } else {
                dispatch({type: DEFAULT_INIT})
            }
        }
    }

    useEffect(() => {
        if (submissionId && processId && processable && !success) {
            process();
        }
    }, [submissionId, moduleId, processable, success]);

    function initProcess() {
        dispatch(initProcessAction(processId, user));
    }

    function initTool() {
        dispatch(initToolAction(toolId));
    }

    function process() {
        dispatch(processAction(processId, submissionId, moduleId));
    }

    const specialInitErrorHandler = useCallback(async (response) => {
        // console.warn('specialInitErrorHandler active')
        dispatch({type: DEFAULT_INIT})
    }, []);

    return (
        <>
            {styleCssString && (
                <Helmet>
                    <style>
                        {styleCssString}
                    </style>
                </Helmet>
            )}
            <div id={'screen-wrapper'}>
                <Loading/>
                <User processId={processId}/>
                <Message/>
                <Module/>
            </div>
        </>
    );
};

export default Screen;
