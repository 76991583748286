import React, {useState, useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import SelectInput from "../../ui/SelectInput";
import TextInput from "../../ui/TextInput";
import {confirmationAction, registrationAction, resendActivationAction} from "../../../store/actions/Customer";
import {Customer, CUSTOMER_FIELD_MAP} from "../../Customer";
import toast from "react-hot-toast";
import TermsInput from "../../ui/TermsInput";
import NewsletterInput from "../../ui/NewsletterInput";
import CaptchaInput from "../../ui/CaptchaInput";
import AlternativeButton from '../../ui/AlternativeButton';
import PcConfig from '../../../utility/PcConfig';
import NextButton from '../../ui/NextButton';

const CustomerRegistration = (props) => {
    // console.log('CustomerRegistration')

    const dispatch = useDispatch();

    const processId = props.processId;
    const submissionId = props.submissionId;
    const module = props.module;

    const loggedIn = useSelector(state => state.Customer.loggedIn);
    const user = useSelector(state => state.Customer.user);

    const [editUser, updateEditUser] = useState(user ? JSON.parse(JSON.stringify(user)) : new Customer({}));
    const [submitted, updateSubmitted] = useState(false);
    const [currentPassword, updateCurrentPassword] = useState(null);
    const [currentPasswordRepeat, updateCurrentPasswordRepeat] = useState(null);

    const [captchaValid, updateCaptchaValid] = useState(false);

    const [accountNotActive, updateAccountNotActive] = useState(false);

    const changeHandler = useCallback((inputIdentifier, inputValue) => {
            if (inputValue !== editUser[inputIdentifier]) {
                let current = editUser;
                current[inputIdentifier] = inputValue;
                updateEditUser(current);
                updateAccountNotActive(false);
            }
        },
        [editUser]
    );

    const captchaHandler = useCallback((valid) => {
            updateCaptchaValid(valid);
        },[]
    );

    // const newsletterHandler = useCallback((inputIdentifier, inputValue) => {
    //         // console.log('HERE I AM')
    //         // console.log(inputIdentifier)
    //         // console.log(inputValue)
    //     },
    //     [editUser]
    // );

    const passwordChangeHandler = useCallback((inputIdentifier, inputValue) => {
            if (inputValue !== currentPassword) {
                updateCurrentPassword(inputValue);
                updateAccountNotActive(false);
            }
        },
        [currentPassword]
    );

    const passwordRepeatChangeHandler = useCallback((inputIdentifier, inputValue) => {
            if (inputValue !== currentPasswordRepeat) {
                updateCurrentPasswordRepeat(inputValue);
                updateAccountNotActive(false);
            }
        },
        [currentPasswordRepeat]
    );

    const errorRequestHandler = useCallback(
        (response) => {
            if (response.notActive === true) {
                updateAccountNotActive(true);
            } else {
                updateAccountNotActive(false);
            }
        },
        []
    );

    function handleSubmit(event) {

        event.preventDefault();

        let valid = true;
        const request = {};
        if (PcConfig.DECODED) {
            request.PcCustomer = {};
            request.User = {};
        }

        // const customerPostName = btoa('PcCustomer');
        // const userPostName = btoa('User');

        CUSTOMER_FIELD_MAP.forEach(ele => {
            if (module.data.contact[ele + '_displayed'] === 1 || ele === 'email') {
                if (module.data.contact[ele + '_required'] === 1 && !editUser[ele]) {
                    valid = false;
                    // console.log('INVALID', ele)
                } else if (editUser[ele]) {
                    if (PcConfig.DECODED) {
                        request.PcCustomer[ele] = editUser[ele];
                        if (ele === 'email') {
                            request.User.username = editUser[ele];
                            request.User.email = editUser[ele];
                            request.PcCustomer.email = editUser[ele];

                        }
                        // const postName = btoa(ele);
                        // request[customerPostName + '[' + postName + ']'] = editUser[ele];
                        // if (ele === 'email') {
                        // request['User[username]'] = editUser[ele];
                        // request['User[email]'] = editUser[ele];
                        // request['PcCustomer[email]'] = editUser[ele];
                        // }
                    } else {
                        request['PcCustomer[' + ele + ']'] = editUser[ele];
                        if (ele === 'email') {
                            request['User[username]'] = editUser[ele];
                            request['User[email]'] = editUser[ele];
                            request['PcCustomer[email]'] = editUser[ele];
                        }
                    }

                }
            }
        });

        if (!loggedIn) {
            if (module.data.contact.password_displayed === 1) {

                if ((currentPassword || currentPasswordRepeat) && currentPassword !== currentPasswordRepeat) {
                    toast.error('Passwörter stimmen nicht überein.');
                    valid = false;

                } else if (module.data.contact.password_required === 1 &&
                    (!currentPassword || !currentPasswordRepeat)
                ) {
                    toast.error('Passwort und Passwort wiederholen sind Pflichtfelder.');
                    valid = false;
                } else if (currentPassword && currentPasswordRepeat) {
                    if (PcConfig.DECODED) {
                        request.User['password'] = currentPassword;
                        request.User['repeat_password'] = currentPasswordRepeat;
                    } else {
                        request['User[password]'] = currentPassword;
                        request['User[repeat_password]'] = currentPasswordRepeat;
                    }
                }
            }
        }

        if (module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 0) {

            if (document.querySelector('#module-terms-condition-checkbox').checked) {
                request['termsConditions'] = 1;
            } else {
                valid = false;
            }
        }

        if (module.data.newsletter) {
            if (document.querySelector('#newsletter-checkbox').checked) {
                request['newsletter'] = 1;
            } else if (module.data.newsletter.required) {
                valid = false;
            }
        }

        if (!loggedIn && captchaValid === false) {
            valid = false;
        }

        if (valid) {
            if (loggedIn) {
                dispatch(confirmationAction(processId, submissionId, module.data.module.id, request));
            } else {
                dispatch(registrationAction(processId, submissionId, module.data.module.id, request, errorRequestHandler));
            }
        } else {
            toast.error('Daten unvollständig / fehlerhaft!');
        }

        updateSubmitted(true);
    }

    function handleResend() {

        const request = {
            username: editUser.email,
            moduleId: module.data.module.id,
        };

        dispatch(resendActivationAction(processId, submissionId, request));
    }

    return (
        <div id="registration-form">
            <form className="fields-container" onSubmit={handleSubmit} autoComplete="off">

                {
                    !loggedIn &&
                    <TextInput
                        id="email"
                        type="email"
                        label="E-Mail"
                        required={1}
                        minLength={7}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.email}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.password_displayed === 1 && !loggedIn &&
                    <TextInput
                        id="password"
                        type="password"
                        label="Passwort"
                        required={module.data.contact.password_required}
                        minLength={8}
                        maxLength={32}
                        onInputChange={passwordChangeHandler}
                        initialValue={currentPassword}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.password_displayed === 1 && !loggedIn &&
                    <TextInput
                        id="password_repeat"
                        type="password"
                        label="Passwort wiederholen"
                        required={module.data.contact.password_required}
                        minLength={8}
                        maxLength={32}
                        onInputChange={passwordRepeatChangeHandler}
                        initialValue={currentPassword}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.salutation_displayed === 1 &&
                    <SelectInput
                        id="salutation"
                        label="Anrede"
                        options={module.data.salutation}
                        required={module.data.contact.salutation_required}
                        onInputChange={changeHandler}
                        // initialValue={ module.data.salutation.find(i => i.value === editUser.salutation)}
                        initialValue={editUser.salutation}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.title_displayed === 1 &&
                    <TextInput
                        id="title"
                        type="text"
                        label="Titel"
                        required={module.data.contact.title_required}
                        minLength={2}
                        maxLength={45}
                        onInputChange={changeHandler}
                        initialValue={editUser.title}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.firstname_displayed === 1 &&
                    <TextInput
                        id="firstname"
                        type="text"
                        label="Vorname"
                        required={module.data.contact.firstname_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.firstname}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.lastname_displayed === 1 &&
                    <TextInput
                        id="lastname"
                        type="text"
                        label="Nachname"
                        required={module.data.contact.lastname_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.lastname}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.street_displayed === 1 &&
                    <TextInput
                        id="street"
                        type="text"
                        label="Straße"
                        required={module.data.contact.street_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.street}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.zip_displayed === 1 &&
                    <TextInput
                        id="zip"
                        type="text"
                        label="PLZ"
                        required={module.data.contact.zip_required}
                        minLength={2}
                        maxLength={10}
                        onInputChange={changeHandler}
                        initialValue={editUser.zip}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.city_displayed === 1 &&
                    <TextInput
                        id="city"
                        type="text"
                        label="Ort"
                        required={module.data.contact.city_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.city}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.state_displayed === 1 &&
                    <TextInput
                        id="state"
                        type="text"
                        label="Bundesland"
                        required={module.data.contact.state_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.state}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.website_displayed === 1 &&
                    <TextInput
                        id="website"
                        type="url"
                        label="Website"
                        required={module.data.contact.website_required}
                        minLength={2}
                        maxLength={255}
                        onInputChange={changeHandler}
                        initialValue={editUser.website}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.phone_displayed === 1 &&
                    <TextInput
                        id="phone"
                        type="text"
                        label="Telefon"
                        required={module.data.contact.phone_required}
                        minLength={2}
                        maxLength={45}
                        onInputChange={changeHandler}
                        initialValue={editUser.phone}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.fax_displayed === 1 &&
                    <TextInput
                        id="fax"
                        type="text"
                        label="Fax"
                        required={module.data.contact.fax_required}
                        minLength={2}
                        maxLength={45}
                        onInputChange={changeHandler}
                        initialValue={editUser.fax}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.mobile_displayed === 1 &&
                    <TextInput
                        id="mobile"
                        type="text"
                        label="Mobil"
                        required={module.data.contact.mobile_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.mobile}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.company_displayed === 1 &&
                    <TextInput
                        id="company"
                        type="text"
                        label="Unternehmen"
                        required={module.data.contact.company_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.company}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.branch_displayed === 1 &&
                    <SelectInput
                        id="branch"
                        label="Branche"
                        options={module.data.branches}
                        required={module.data.contact.branch_required}
                        onInputChange={changeHandler}
                        initialValue={editUser.branch}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.department_displayed === 1 &&
                    <TextInput
                        id="department"
                        type="text"
                        label="Abteilung"
                        required={module.data.contact.department_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.department}
                        submitted={submitted}
                    />
                }

                {
                    module.data.contact.facebook_displayed === 1 &&
                    <TextInput
                        id="facebook"
                        type="text"
                        label="Facebook"
                        required={module.data.contact.facebook_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.facebook}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.xing_displayed === 1 &&
                    <TextInput
                        id="xing"
                        type="text"
                        label="Xing"
                        required={module.data.contact.xing_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.xing}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.linkedin_displayed === 1 &&
                    <TextInput
                        id="linkedin"
                        type="text"
                        label="LinkedIn"
                        required={module.data.contact.linkedin_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.linkedin}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.twitter_displayed === 1 &&
                    <TextInput
                        id="twitter"
                        type="text"
                        label="Twitter"
                        required={module.data.contact.twitter_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.twitter}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.instagram_displayed === 1 &&
                    <TextInput
                        id="instagram"
                        type="text"
                        label="Instagram"
                        required={module.data.contact.instagram_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.instagram}
                        submitted={submitted}
                    />
                }
                {
                    module.data.contact.tiktok_displayed === 1 &&
                    <TextInput
                        id="tiktok"
                        type="text"
                        label="TikTok"
                        required={module.data.contact.tiktok_required}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.tiktok}
                        submitted={submitted}
                    />
                }
                {
                    module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 0 &&
                    <TermsInput
                        text={module.data.module.terms_conditions}
                        submitted={submitted}
                    />
                }
                {
                    module.data.newsletter &&
                    <NewsletterInput
                        text={module.data.newsletter.description}
                        required={module.data.newsletter.required}
                        onInputChange={() => {}}
                        submitted={submitted}
                    />
                }
                {
                    !loggedIn &&
                    <CaptchaInput
                        onInputChange={captchaHandler}
                    />
                }
                <NextButton type="submit">Registrieren</NextButton>
            </form>
            {
                accountNotActive &&
                <div className="button-container">
                    <AlternativeButton onClick={handleResend}>Aktivierungs-E-Mail erneut senden</AlternativeButton>
                </div>
            }
        </div>
    );
}

export default CustomerRegistration