import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import TextInput from "../../ui/TextInput";

import {
    CUSTOMER_BACK,
    editProfileAction,
    loadSalutationsAction,
    loadBranchesAction,
    deleteAccountAction, cancelDeleteAccountAction
} from "../../../store/actions/Customer";
import {CUSTOMER_FIELD_MAP} from "../../Customer";

import {DISPLAY_DOWNLOADS} from "../../../store/actions/Download";
import SelectInput from "../../ui/SelectInput";
import Button from '../../ui/Button';
import {
    faArrowDownToLine as fatArrowDownToLine,
    faArrowRight as fatArrowRight,
    faArrowLeft as fatArrowLeft,
    faTrashCan as fatTrashCan,
    faXmark as fatXmark
} from '@fortawesome/pro-thin-svg-icons';

const Profile = (props) => {

    // console.log('Profile')

    const dispatch = useDispatch();

    const user = useSelector(state => state.Customer.user);
    const requireDelete = useSelector(state => state.Customer.user.require_delete);
    const salutations = useSelector(state => state.Customer.salutations);
    const branches = useSelector(state => state.Customer.branches);

    const [editUser, updateEditUser] = useState(user);
    const [salutationsLoaded, updateSalutationsLoaded] = useState(false);
    const [branchesLoaded, updateBranchesLoaded] = useState(false);

    useEffect(() => {
        if(!salutations.length && !salutationsLoaded) {
            updateSalutationsLoaded(true);
            dispatch(loadSalutationsAction());
        }
    }, [dispatch, salutations, salutationsLoaded]);

    useEffect(() => {
        if(!branches.length && !branchesLoaded) {
            updateBranchesLoaded(true);
            dispatch(loadBranchesAction());
        }
    }, [dispatch, branches, branchesLoaded]);

    const changeHandler = useCallback((inputIdentifier, inputValue) => {
            if (inputValue !== editUser[inputIdentifier]) {
                let current = editUser;
                current[inputIdentifier] = inputValue;
                updateEditUser(current);
            }
        },
        [editUser]
    );

    const backHandler = useCallback(() => {
            dispatch({
                type: CUSTOMER_BACK,
            });
        },
        [dispatch]
    );

    function handleSubmit(event) {

        event.preventDefault();

        let valid = true;

        const request = {};

        CUSTOMER_FIELD_MAP.forEach(ele => {

            if (user[ele] && !editUser[ele]) {
                valid = false;
            } else if (editUser[ele]) {
                request['PcCustomer[' + ele + ']'] = editUser[ele];
            }

        });

        if (valid) {
            dispatch(editProfileAction(request));
        }
    }

    function downloadsHandler(event) {
        dispatch({
            type: DISPLAY_DOWNLOADS,
            view: 'profileDownload',
        });
    }

    function deleteHandler() {
        dispatch(deleteAccountAction({id: user.id, user_id: user.user_id}));
    }

    function cancelDeleteHandler() {
        dispatch(cancelDeleteAccountAction({id: user.id, user_id: user.user_id}));
    }

    if (user.id) {
        return (
            <div className="module" id={'modulewrapper'}>
                <div className="button-row">
                    <Button primary icon={fatArrowLeft} iconPosition="left" onClick={backHandler}>
                        Zurück
                    </Button>
                    <Button primary icon={fatArrowDownToLine} onClick={downloadsHandler}>Meine Downloads</Button>
                    {
                        requireDelete ?
                            <Button primary icon={fatXmark} onClick={cancelDeleteHandler}>Account Löschung abbrechen</Button>
                            :
                            <Button primary icon={fatTrashCan} onClick={deleteHandler}>Account löschen</Button>
                    }
                </div>

                <form className="fields-container" onSubmit={handleSubmit} autoComplete="off">

                    {/* PERSONAL FIELDS */}
                    <SelectInput
                        id="salutation"
                        label="Anrede"
                        options={salutations}
                        required={user.salutation ? 1 : 0}
                        initialValue={editUser.salutation}
                        onInputChange={changeHandler}
                    />

                    <TextInput
                        id="title"
                        type="text"
                        label="Title"
                        required={user.title ? 1 : 0}
                        minLength={2}
                        maxLength={45}
                        onInputChange={changeHandler}
                        initialValue={editUser.title}
                    />

                    <TextInput
                        id="firstname"
                        type="text"
                        label="Vorname"
                        required={user.firstname ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.firstname}
                    />

                    <TextInput
                        id="lastname"
                        type="text"
                        label="Nachname"
                        required={user.lastname ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.lastname}
                    />

                    <TextInput
                        id="street"
                        type="text"
                        label="Straße"
                        required={user.street ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.street}
                    />

                    <TextInput
                        id="zip"
                        type="text"
                        label="PLZ"
                        required={user.zip ? 1 : 0}
                        minLength={2}
                        maxLength={10}
                        onInputChange={changeHandler}
                        initialValue={editUser.zip}
                    />

                    <TextInput
                        id="city"
                        type="text"
                        label="Ort"
                        required={user.city ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.city}
                    />

                    <TextInput
                        id="state"
                        type="text"
                        label="Bundesland"
                        required={user.state ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.state}
                    />

                    <TextInput
                        id="website"
                        type="text"
                        label="Website"
                        required={user.website ? 1 : 0}
                        minLength={2}
                        maxLength={255}
                        onInputChange={changeHandler}
                        initialValue={editUser.website}
                    />

                    <TextInput
                        id="phone"
                        type="text"
                        label="Telefon"
                        required={user.phone ? 1 : 0}
                        minLength={2}
                        maxLength={45}
                        onInputChange={changeHandler}
                        initialValue={editUser.phone}
                    />

                    <TextInput
                        id="fax"
                        type="text"
                        label="Fax"
                        required={user.fax ? 1 : 0}
                        minLength={2}
                        maxLength={45}
                        onInputChange={changeHandler}
                        initialValue={editUser.fax}
                    />

                    <TextInput
                        id="mobile"
                        type="text"
                        label="Mobil"
                        required={user.mobile ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.mobile}
                    />

                    {/* COMPANY FIELDS*/}

                    <SelectInput
                        id="branch"
                        label="Branche"
                        options={branches}
                        required={user.branch ? 1 : 0}
                        initialValue={editUser.branch}
                        onInputChange={changeHandler}
                    />
                    <TextInput
                        id="company"
                        type="text"
                        label="Unternehmen"
                        required={user.company ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.company}
                    />

                    <TextInput
                        id="depatment"
                        type="text"
                        label="Abteilung"
                        required={user.department ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.department}
                    />

                    {/* SOCIAL FIELDS */}

                    <TextInput
                        id="facebook"
                        type="text"
                        label="Facebook"
                        required={user.facebook ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.facebook}
                    />

                    <TextInput
                        id="xing"
                        type="text"
                        label="Xing"
                        required={user.xing ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.xing}
                    />

                    <TextInput
                        id="linkedin"
                        type="text"
                        label="Linkedin"
                        required={user.linkedin ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.linkedin}
                    />

                    <TextInput
                        id="twitter"
                        type="text"
                        label="Twitter"
                        required={user.twitter ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.twitter}
                    />

                    <TextInput
                        id="instagram"
                        type="text"
                        label="Instagram"
                        required={user.instagram ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.instagram}
                    />

                    <TextInput
                        id="tiktok"
                        type="text"
                        label="TikTok"
                        required={user.tiktok ? 1 : 0}
                        minLength={2}
                        maxLength={127}
                        onInputChange={changeHandler}
                        initialValue={editUser.tiktok}
                    />
                    <Button primary icon={fatArrowRight} type="submit">Bearbeiten</Button>
                </form>
            </div>
        );
    }
}

export default Profile