import { useEffect, useRef, useState } from 'react';
import { join } from '../../utility/Css'

export default function Collapsable({
    collapsed = false,
    contentContainerClassName,
    contentContainerStyle,
    component = 'div',
    style,
    children,
    ...props
}) {
    const [height, setHeight] = useState(collapsed ? '0' : '');
    const contentContainerRef = useRef();

    useEffect(() => {
        if (collapsed) return setHeight(0);

        const currentElement = contentContainerRef.current;

        if (!currentElement)
            return;

        const resizeObserver = new ResizeObserver(([element]) => {
            setHeight(element?.contentRect.height);
        });

        resizeObserver.observe(currentElement);

        return () => {
            resizeObserver.unobserve(currentElement);
        }
    }, [collapsed]);
    
    const Component = component;
    const heightCssValue = collapsed ? '0' : (height && height + 'px');

    return (
        <Component
            className={join('collapsable', collapsed && 'collapsed')}
            style={{ height: heightCssValue, ...style }}
            {...props}
        >
            <div
                ref={contentContainerRef}
                className={join('collapsable-content', contentContainerClassName)}
                style={contentContainerStyle}
            >
                {children}
            </div>
        </Component>
    );
}