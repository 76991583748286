import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import TextInput from "../../ui/TextInput";

import {
    CUSTOMER_BACK,
    profileLoginAction,
    resendActivationAction,
    showForgetAction
} from "../../../store/actions/Customer";
import Button from '../../ui/Button';
import {
    faArrowLeft as fatArrowLeft,
    faArrowRight as fatArrowRight
} from '@fortawesome/pro-thin-svg-icons';
import toast from "react-hot-toast";
import CaptchaInput from "../../ui/CaptchaInput";
import AlternativeButton from '../../ui/AlternativeButton';

const Login = (props) => {
    // console.log('Login')

    const dispatch = useDispatch();

    const processId = useSelector(state => state.App.processId);
    const submissionId = useSelector(state => state.App.submissionId);
    const user = useSelector(state => state.Customer.user);

    const [accountNotActive, updateAccountNotActive] = useState(false);

    const [captchaValid, updateCaptchaValid] = useState(false);
    const captchaHandler = useCallback((valid) => {
        updateCaptchaValid(valid);
    }, []);

    function backHandler() {
        dispatch({
            type: CUSTOMER_BACK,
        });
    }

    const [currentUsername, updateCurrentUsername] = useState('');
    const usernameChangeHandler = useCallback(async (inputIdentifier, inputValue) => {
            if (inputValue !== currentUsername) {
                updateCurrentUsername(inputValue);
                updateAccountNotActive(false);
            }
        },
        [currentUsername]
    );

    const [currentPassword, updateCurrentPassword] = useState('');
    const passwordChangeHandler = useCallback(async (inputIdentifier, inputValue) => {
            if (inputValue !== currentPassword) {
                updateCurrentPassword(inputValue);
                updateAccountNotActive(false);
            }
        },
        [currentPassword]
    );

    function handleSubmit(event) {

        event.preventDefault();

        let valid = true;

        if(currentUsername.length < 7 && currentPassword.length < 8) {
            valid = false;
        }

        if(captchaValid === false) {
            valid = false;
        }

        if(valid) {
            const request = {
                username: currentUsername,
                password: currentPassword
            };
            dispatch(profileLoginAction(processId, submissionId, request, errorRequestHandler));
        } else {
            toast.error('Daten unvollständig / fehlerhaft!');
        }
    }

    function handleForget() {
        dispatch(showForgetAction());
    }

    function handleResend() {

        const request = {
            username: currentUsername,
            // password: currentPassword,
        };

        dispatch(resendActivationAction(processId, submissionId, request));
    }


    const errorRequestHandler = useCallback((response) => {
            if (response.notActive === true) {
                updateAccountNotActive(true);
            } else {
                updateAccountNotActive(false);
            }
        },
        []
    );

    if(!user.id) {
        return (
            <div>
                <Button className="button-back" primary icon={fatArrowLeft} iconPosition="left" onClick={backHandler}>
                    Zurück
                </Button>
                <form className="fields-container" onSubmit={handleSubmit} autoComplete="off">
                    <TextInput
                        id="username"
                        type="text"
                        label="E-Mail"
                        required={1}
                        minLength={7}
                        maxLength={127}
                        onInputChange={usernameChangeHandler}
                        initialValue={currentUsername}
                    />
                    <TextInput
                        id="password"
                        type="password"
                        label="Passwort"
                        required={1}
                        minLength={8}
                        maxLength={32}
                        onInputChange={passwordChangeHandler}
                        initialValue={currentPassword}
                    />
                    <CaptchaInput
                        onInputChange={captchaHandler}
                    />
                    <div className="button-container">
                        <Button primary icon={fatArrowRight} type="submit">Senden</Button>
                        <AlternativeButton onClick={handleForget}>Passwort vergessen</AlternativeButton>
                        {accountNotActive && (
                            <AlternativeButton onClick={handleResend}>
                                Aktivierungs E-Mail erneut senden
                            </AlternativeButton>
                        )}
                    </div>
                </form>                
            </div>
        );
    }
}

export default Login