import {
    faArrowRightFromBracket as fatArrowRightFromBracket,
    faUser as fatUser
} from '@fortawesome/pro-thin-svg-icons';
import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {
    initCustomerAction,
    logoutAction,
    profileAction,
    showLoginAction
} from "../../store/actions/Customer";
import Button from './Button';

const User = (props) => {
    // console.log('USER')

    const dispatch = useDispatch();

    const user = useSelector(state => state.Customer.user);
    const userInit = useSelector(state => state.App.userInit);
    const view = useSelector(state => state.App.view);

    useEffect(() => {
        if (!user.id && !userInit) {
            dispatch(initCustomerAction(props));
        }
    }, [dispatch, user, userInit]);


    function handleLogout() {
        dispatch(logoutAction());
    }

    function handleProfile() {
        dispatch(profileAction());
    }

    function handleLogin() {
        dispatch(showLoginAction());
    }

    const showProfile = !!user.user_id;
    const showLoginNavigation = view !== 'login' && !user.id;

    if (view !== 'error') {
        return (
            (showProfile || showLoginNavigation) &&
            <div className="user-container">
                {
                    showProfile ?
                        <div className="button-row">
                            <Button primary icon={fatUser} onClick={handleProfile}>Profil ({user.firstname ? user.firstname : user.email})
                            </Button>
                            <Button primary icon={fatArrowRightFromBracket} onClick={handleLogout}>
                                Logout
                            </Button>
                        </div>
                        :
                        (
                            showLoginNavigation &&
                            <div className="button-row">
                                <Button primary icon={fatUser} onClick={handleLogin}>Login</Button>
                            </div>
                        )

                }
            </div>
        );
    }
}

export default User;