import React, {useState, useCallback} from 'react';
import {useDispatch} from "react-redux";

import TextInput from "../../ui/TextInput";
import {loginAction, resendActivationAction, showForgetAction} from "../../../store/actions/Customer";
import AlternativeButton from '../../ui/AlternativeButton';
import CaptchaInput from "../../ui/CaptchaInput";
import toast from "react-hot-toast";
import NextButton from '../../ui/NextButton';

const CustomerLogin = (props) => {

    const dispatch = useDispatch();

    const processId = props.processId;
    const submissionId = props.submissionId;
    const moduleId = props.moduleId;

    const [accountNotActive, updateAccountNotActive] = useState(false);

    const [currentUsername, updateCurrentUsername] = useState('');
    const usernameChangeHandler = useCallback((inputIdentifier, inputValue) => {
            if (inputValue !== currentUsername) {
                updateCurrentUsername(inputValue);
                updateAccountNotActive(false);
            }
        },
        [currentUsername]
    );

    const [currentPassword, updateCurrentPassword] = useState('');
    const passwordChangeHandler = useCallback((inputIdentifier, inputValue) => {

            if (inputValue !== currentPassword) {
                updateCurrentPassword(inputValue);
                updateAccountNotActive(false);
            }
        },
        [currentPassword]
    );

    const [captchaValid, updateCaptchaValid] = useState(false);

    const captchaHandler = useCallback((valid) => {
        updateCaptchaValid(valid);
    }, []
    );

    const errorRequestHandler = useCallback((response) => {
            if (response.notActive === true) {
                updateAccountNotActive(true);
            } else {
                updateAccountNotActive(false);
            }
        }, []
    );

    function handleSubmit(event) {

        event.preventDefault();

        let valid = true;

        if(currentUsername.length < 7 && currentPassword.length < 8) {
            valid = false;
        }

        if(captchaValid === false) {
            valid = false;
        }

        if(valid) {
            const request = {
                username: currentUsername,
                password: currentPassword,
            };

            dispatch(loginAction(processId, submissionId, moduleId, request, errorRequestHandler));
        } else {
            toast.error('Daten unvollständig / fehlerhaft!');
        }
    }

    function handleForget() {
        dispatch(showForgetAction());
    }

    function handleResend() {

        const request = {
            username: currentUsername,
            moduleId: moduleId,
        };

        dispatch(resendActivationAction(processId, submissionId, request));
    }

    return (
        <div>
            <form className="fields-container" onSubmit={handleSubmit} autoComplete="off">
                <TextInput
                    id="username"
                    type="email"
                    label="E-Mail"
                    required={1}
                    minLength={7}
                    maxLength={127}
                    onInputChange={usernameChangeHandler}
                    initialValue={currentUsername}
                />
                <TextInput
                    id="password"
                    type="password"
                    label="Passwort"
                    required={1}
                    minLength={8}
                    maxLength={32}
                    onInputChange={passwordChangeHandler}
                    initialValue={currentPassword}
                />
                <CaptchaInput
                    onInputChange={captchaHandler}
                />
                <NextButton type="submit">Einloggen</NextButton>
            </form>
            <AlternativeButton onClick={handleForget}>Passwort vergessen</AlternativeButton>
            {
                accountNotActive &&
                <div className="button-container">
                    <AlternativeButton onClick={handleResend}>Aktivierungs E-Mail erneut senden</AlternativeButton>
                </div>
            }
        </div>
    );
}

export default CustomerLogin