import {doServerRequest, startLoading, stopLoading} from "./Webservice";
import {PROCESS} from "./App";
import {toastResponseMessages} from "../../components/ui/Message";

export const PAYMENT_ROUTE = 'pc-api-payment/';

export const offlinePaymentAction = (submissionId, moduleId, type, data = {}) => {
    return async (dispatch) => {
        startLoading(dispatch);

        data.submissionId = submissionId;
        data.moduleId = moduleId;
        data.type = type;

        const response = await doServerRequest(PAYMENT_ROUTE + 'offline-payment', data);

        if (response.status === 'success') {
            dispatch({
                type: PROCESS,
                ...response,
            });

        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const onlinePaymentAction = (submissionId, moduleId, type, details, data, dataObj = {},
                                    errorFunction = null
                                    ) => {
    return async (dispatch) => {
        startLoading(dispatch);

        dataObj.submissionId = submissionId;
        dataObj.moduleId = moduleId;
        dataObj.type = type;
        dataObj.details = JSON.stringify(details);
        dataObj.data = JSON.stringify(data);

        const response = await doServerRequest(PAYMENT_ROUTE + 'online-payment', dataObj);

        if (response.status === 'success') {
            dispatch({
                type: PROCESS,
                ...response,
            });

        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
            if(typeof errorFunction === 'function') {
                errorFunction(response);
            }
        }

        stopLoading(dispatch, response);
    };
};