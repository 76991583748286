const getConfig = () => {
    try {
        return JSON.parse(process.env.REACT_APP_PC_CONFIG);
    }
    catch(error) {
        throw new Error('Invalid PcConfig!', { cause: error });
    }
}

const PcConfig = getConfig();

export default PcConfig;