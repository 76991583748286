import React, {useReducer, useEffect} from 'react';
import Select from 'react-select'

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const createCssClassSetter = cssClass => {
    return state => cssClass
        + (state.isFocused ? ' focused' : '')
        + (state.isSelected ? ' selected' : '')
        + (state.isDisabled ? ' disabled' : '');
}

const cssClassNames = {
    clearIndicator: createCssClassSetter('select-indicator-clear'),
    container: () => 'select-container',
    control: createCssClassSetter('select-control'),
    dropdownIndicator: createCssClassSetter('select-indicator-dropdown'),
    indicatorsContainer: () => 'select-indicator-container',
    indicatorSeparator: () => 'select-indicator-seperator',
    menu: createCssClassSetter('select-menu'),
    menuList: createCssClassSetter('select-option-list'),
    option: createCssClassSetter('select-option'),
    placeholder: () => 'select-placeholder',
    valueContainer: createCssClassSetter('select-value-container'),
}

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid,
                touched: true
            };
        case INPUT_BLUR:
            return {
                ...state,
                touched: true
            };
        default:
            return state;
    }
};

const SelectInput = (props) => {

    // console.log('SelectInput')

    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue ? props.initialValue : '',
        isValid: ((props.required === 1 && props.initialValue) || props.required === 0),
        touched: props.initialTouched ? props.initialTouched : false,
    });

    // const [errorIcon, setErrorIcon] = useState('');
    // const [errorIconColor, setErrorIconColor] = useState('');
    const {onInputChange, id} = props;

    useEffect(() => {
        if (inputState.touched) {
            onInputChange(id, inputState.value, inputState.isValid);
        }
        if (inputState.isValid === false && inputState.touched) {
            // DoToast('danger', props.errorText);
            // setErrorIcon('close-circle');
            // setErrorIconColor(Colors.alarmColor);
        }
        if (inputState.isValid && inputState.touched) {
            // setErrorIcon('checkmark-circle');
            // setErrorIconColor(Colors.secondaryColor);
        }
    }, [inputState, onInputChange, id]);

    const selectChangeHandler = event => {

        let key = null;
        let isValid = true;
        if(event) {
            key = event.value;
            if (props.required && !key) {
                isValid = false;
            }
        }

        dispatch({type: INPUT_CHANGE, value: key, isValid: isValid});
    };

    const lostFocusHandler = () => {
        dispatch({type: INPUT_BLUR});
    };


    const required = [];
    if(props.required === 1) {
        required.push('required');
    }

    return (
        <div className={'input input-select ' + ((inputState.isValid === false || typeof inputState.isValid === 'undefined') && (inputState.touched || props.submitted) ? 'error' : '')}>
            <label className="input-label" htmlFor={'pc_field_' + id}>{props.label}{props.required === 1 && '*'}</label>
            <Select
                inputId={'pc_field_' + id}
                classNames={cssClassNames}
                options={props.options}
                onChange={selectChangeHandler}
                onBlur={lostFocusHandler}
                placeholder={'- Bitte wählen -'}
                isClearable={!props.required}
                // defaultInputValue={inputState.value}
                value={props.options.find(i => parseInt(i.value) === parseInt(inputState.value))}
            />
            {
                !!props.required &&
                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0, position: 'absolute' }}
                    value={inputState.value}
                    required={required}
                    readOnly={true}
                />
            }

            {
                props.description &&
                <p className="input-description" dangerouslySetInnerHTML={{ __html: props.description }} />
            }
        </div>
    );
}

export default SelectInput