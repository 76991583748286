import {doServerRequest, startLoading, stopLoading} from "./Webservice";
import {PROCESS} from "./App";
import toast from 'react-hot-toast';
import {toastResponseMessages} from "../../components/ui/Message";

export const CUSTOMER_ROUTE = 'pc-api-customer/';

export const INIT_CUSTOMER = 'INIT_CUSTOMER';
export const LOGOUT_CUSTOMER = 'LOGOUT_CUSTOMER';
export const APPROVED = 'APPROVED';
export const VIEW_CUSTOMER = 'VIEW_CUSTOMER';
export const VIEW_FORGOT = 'VIEW_FORGOT';
export const EMAIL_CONFIRMED = 'EMAIL_CONFIRMED';
export const CUSTOMER_BACK = 'CUSTOMER_BACK';
export const PROFILE_LOGIN = 'PROFILE_LOGIN';
export const MODULE_LOGIN = 'MODULE_LOGIN';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const SALUTATIONS_LOADED = 'SALUTATIONS_LOADED';
export const BRANCHES_LOADED = 'BRANCHES_LOADED';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const CANCEL_DELETE_ACCOUNT = 'CANCEL_DELETE_ACCOUNT';

export const initCustomerAction = (data) => {

    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-init', data);

        if (response && response.status === 'success') {
            dispatch({
                type: INIT_CUSTOMER,
                ...response
            });

        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const registrationAction = (processId, submissionId, moduleId, data, errorFunction = null) => {
    return async (dispatch) => {
        startLoading(dispatch);

        data.processId = processId
        data.submissionId = submissionId
        data.moduleId = moduleId

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-register', data);

        if (response && response.status === 'success') {
            dispatch({
                type: PROCESS,
                ...response,
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
            if(typeof errorFunction === 'function') {
                errorFunction(response);
            }
        }

        stopLoading(dispatch, response);
    };
};

export const confirmationAction = (processId, submissionId, moduleId, data, errorFunction = null) => {
    return async (dispatch) => {
        startLoading(dispatch);

        data.processId = processId
        data.submissionId = submissionId
        data.moduleId = moduleId

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-confirm', data);

        if (response && response.status === 'success') {
            dispatch({
                type: PROCESS,
                ...response
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
            if(typeof errorFunction === 'function') {
                errorFunction(response);
            }
        }

        stopLoading(dispatch, response);
    };
};

export const loginAction = (processId, submissionId, moduleId, data, errorFunction) => {
    return async (dispatch) => {
        startLoading(dispatch);

        data.processId = processId
        data.submissionId = submissionId
        data.moduleId = moduleId

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-login', data);

        if (response && response.status === 'success') {
            if(typeof response.msg === 'string') {
                toast.success(response.msg);
            }
            dispatch({
                type: MODULE_LOGIN,
                ...response
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
            if(typeof errorFunction === 'function') {
                errorFunction(response);
            }
        }

        stopLoading(dispatch, response);
    };
};

export const profileLoginAction = (processId, submissionId, data, errorRequestHandler) => {
    return async (dispatch) => {
        startLoading(dispatch);

        data.processId = processId
        data.submissionId = submissionId
        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-profile-login', data);

        if (response && response.status === 'success') {
            dispatch({
                type: PROFILE_LOGIN,
                ...response
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
            if(typeof errorRequestHandler === 'function') {
                errorRequestHandler(response);
            }
        }

        stopLoading(dispatch, response);
    };
};

export const logoutAction = () => {
    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-logout');

        if (response && response.status === 'success') {
            dispatch({
                type: LOGOUT_CUSTOMER,
            });
            toast.success('Erfolgreich abgemeldet.');
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const forgetAction = (data) => {
    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-profile-forget', data);

        if (response && response.status === 'success') {
            toast.success(response.msg);
            dispatch({
                type: VIEW_CUSTOMER,
                view: 'login'
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const resetAction = (data) => {
    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-profile-reset', data);

        if (response && response.status === 'success') {
            toast.success(response.msg + ' Sie werden weitergeleitet.');
            dispatch({
                type: PASSWORD_RESET,
                view: 'login'
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const resendActivationAction = (processId, submissionId, request) => {
    return async (dispatch) => {
        startLoading(dispatch);

        request.processId = processId
        request.submissionId = submissionId

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-resend-activation', request);

        if (response && response.status === 'success') {
            dispatch({
                type: PROCESS,
                ...response,
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const editProfileAction = (data) => {
    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-profile-edit', data);

        if (response.status === 'success') {
            toast.success('Gespeichert.');
        } else if(response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const deleteAccountAction = (data) => {
    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-delete-account', data);

        if (response.status === 'success') {
            toast.success('Account Löschung beauftragt.');
            dispatch({
                type: DELETE_ACCOUNT
            })
        } else if(response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const cancelDeleteAccountAction = (data) => {
    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-cancel-delete-account', data);

        if (response.status === 'success') {
            toast.success('Account Löschung abgebrochen.');
            dispatch({
                type: CANCEL_DELETE_ACCOUNT
            })
        } else if(response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const passwordResetAction = (processId, data, errorFunction = null) => {
    return async (dispatch) => {
        startLoading(dispatch);

        data.processId = processId

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-reset-token', data);

        if (response && response.status === 'success') {
            dispatch({
                    type: VIEW_FORGOT,
                    view: 'forgot',
                    token: data.token
                });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
            if(typeof errorFunction === 'function') {
                errorFunction(response);
            }
        }

        stopLoading(dispatch, response);
    };
};

export const emailConfirmedAction = (processId, submissionId, data, errorFunction = null) => {
    return async (dispatch) => {
        startLoading(dispatch);

        data.processId = processId
        data.submissionId = submissionId

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-email-confirmed', data);

        if (response && response.status === 'success') {
            toast.success('E-Mail Adresse bestätigt.');
            dispatch({
                type: EMAIL_CONFIRMED,
                ...response
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
            if(typeof errorFunction === 'function') {
                errorFunction(response);
            }
        }

        stopLoading(dispatch, response);
    };
};

export const approvedProcessAction = (processId, data, errorFunction = null) => {
    return async (dispatch) => {
        startLoading(dispatch);

        data.processId = processId;

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-approved', data);

        if (response && response.status === 'success') {
            dispatch({
                type: APPROVED,
                ...response,
            });

            if(response.user !== null) {
                dispatch({
                    type: INIT_CUSTOMER,
                    user: response.user,
                });
            }
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
            if(typeof errorFunction === 'function') {
                errorFunction(response);
            }
        }

        stopLoading(dispatch, response);
    };
};

export const loadSalutationsAction = () => {
    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-load-salutations');

        if (response && response.status === 'success') {

            dispatch({
                type: SALUTATIONS_LOADED,
                ...response
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const loadBranchesAction = () => {
    return async (dispatch) => {
        startLoading(dispatch);

        const response = await doServerRequest(CUSTOMER_ROUTE + 'app-load-branches');

        if (response && response.status === 'success') {

            dispatch({
                type: BRANCHES_LOADED,
                ...response
            });
        } else if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};


export const profileAction = () => {
    return async (dispatch) => {
        dispatch({
            type: VIEW_CUSTOMER,
            view: 'profile'
        });
    };
};

export const showLoginAction = () => {
    return async (dispatch) => {
        dispatch({
            type: VIEW_CUSTOMER,
            view: 'login'
        });
    };
};

export const showForgetAction = () => {
    return async (dispatch) => {
        dispatch({
            type: VIEW_CUSTOMER,
            view: 'forget'
        });
    };
};