import React, {useCallback, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {proceedAction} from "../../../store/actions/App";
import {codeDownloadsAction, downloadCodeAction, downloadModuleAction} from "../../../store/actions/Download";
import TermsInput from "../../ui/TermsInput";
import toast from "react-hot-toast";
import ProcessBtn from "../../ui/ProcessBtn";
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import {faArrowDownToLine as fatArrowDownToLine, faArrowRight as fatArrowRight} from '@fortawesome/pro-thin-svg-icons';
import DatainputComponents from "../../helpers/DatainputComponent";

const Download = (props) => {
    // console.log('Download')

    const dispatch = useDispatch();
    const [code, updateCode] = useState(false);
    const downloads = useSelector(state => state.Download.codeDownloads);
    const currentCode = useSelector(state => state.Download.currentCode);

    const processId = useSelector(state => state.App.processId);
    const submissionId = useSelector(state => state.App.submissionId);
    const module = useSelector(state => state.App.module);
    const requestPath = 'pc-api-' + module.name + '/submit'

    const [submitted, updateSubmitted] = useState(false);

    const dataChangeHandler = useCallback(async (inputIdentifier, inputValue, isValid) => {
        updateCode(inputValue);
    },[]);

    function codeHandler(event) {
        event.preventDefault();
        dispatch(codeDownloadsAction(code));
    }

    function fileHandler(id) {
        dispatch(downloadCodeAction(id, currentCode, submissionId));
    }

    function handleProceed ()  {
        updateSubmitted(true);

        if((module.data.module.display_terms_conditions && module.data.module.terms_conditions)) {

            if(document.querySelector('#module-terms-condition-checkbox').checked) {
                dispatch(proceedAction(requestPath, processId, submissionId, module.data.module.id, 1));
            } else {
                toast.error('Nicht alle erforderlichen Felder ausgefüllt.');
            }

        } else {
            dispatch(proceedAction(requestPath, processId, submissionId, module.data.module.id));
        }
    }

    function downloadHandler(id) {
        dispatch(downloadModuleAction(id, submissionId, module.data.module.id, processId));
    }

    return (
        <div className="downloads fields-container">

            {
                module.data.download.code_download === 1 &&
                <div>
                    <form className="fields-container" onSubmit={codeHandler} autoComplete="off">
                        {DatainputComponents('text', {
                            id: 'download-code-input',
                            type: 'text',
                            label: 'Download-Code',
                            description: 'Geben sie hier Ihren Download-Code ein.',
                            required: 1,
                            onInputChange: dataChangeHandler,

                        })}
                        <Button primary icon={fatArrowRight} type="submit">Code absenden</Button>
                    </form>

                    {
                        downloads.length > 0 && currentCode &&
                        <div>
                            <Heading level="3" className="download-heading">
                                Für den Code "{currentCode}" verfügbare Dateien:
                            </Heading>

                            {downloads.map((download) => {
                                return (
                                    <div className="download" key={download.id}>
                                        <Heading level="4" className="download-heading">
                                            {download.title}
                                        </Heading>
                                        {download.description && <p
                                            className="input-description"
                                            dangerouslySetInnerHTML={{ __html: download.description }}
                                        />}
                                        <Button
                                            className="download-button"
                                            primary
                                            icon={fatArrowDownToLine}
                                            onClick={() => fileHandler(download.id)}
                                        >
                                            Herunterladen
                                        </Button>
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>
            }



            {module.data.files.map((download) => {
                    return (
                        <div className="download" key={download.id}>
                            <Heading level="2" className="download-heading">
                                {download.display_name}
                            </Heading>
                            {download.description && <p
                                className="input-description"
                                dangerouslySetInnerHTML={{ __html: download.description }}
                            />}
                            <Button
                                className="download-button"
                                primary
                                icon={fatArrowDownToLine}
                                onClick={() => downloadHandler(download.id)}
                            >
                                Herunterladen
                            </Button>
                        </div>
                    );
                }
            )}
            {
                module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 0 &&
                <TermsInput
                    text={module.data.module.terms_conditions}
                    submitted={submitted}
                />
            }
            <ProcessBtn btnText={module.data.module.submit_btn_text} handleProceed={handleProceed}/>
        </div>
    );
}

export default Download