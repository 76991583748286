import {SET_DOWNLOADS, SET_CODE_DOWNLOADS} from "../actions/Download";
import {LOGOUT_CUSTOMER} from "../actions/Customer";

const initialState = {
    loaded: false,
    currentCode: null,
    downloads: [],
    codeDownloads: []
}

// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_DOWNLOADS:
            return {
                ...state,
                downloads: action.downloads,
                loaded: true,
            };
        case SET_CODE_DOWNLOADS:
            return {
                ...state,
                codeDownloads: action.downloads,
                currentCode: action.code,
            };
        case LOGOUT_CUSTOMER:
            return {
                downloads: [],
                codeDownloads: [],
                loaded: false,
                currentCode: null,
            };
        default:
            return state
    }
};