import React from 'react';
import { useSearchParams } from "react-router-dom";

import Screen from "./Screen";
import {b64DecodeUnicode} from "../store/actions/Webservice";
import PcConfig from '../utility/PcConfig';

const Process = (props) => {
// console.log('Process')

    const [searchParams] = useSearchParams();

    let processId = searchParams.get("processId");
    let toolId = searchParams.get("toolId");
    const preview = searchParams.get("preview");

    if(processId) {
        try {
            processId = PcConfig.DECODED ? b64DecodeUnicode(processId) : processId;
        } catch(e) {
            return (
                <div id={'screen-wrapper'} className={'error-wrapper'}>
                    <p>Fehler beim App Start. Bitte laden Sie die Seite neu.</p>
                </div>
            )
        }

        return (
            <Screen processId={processId} preview={preview}/>
        );
    } else if (toolId) {
        try {
          toolId = PcConfig.DECODED ? b64DecodeUnicode(toolId) : toolId;
        } catch(e) {
            return (
                <div id={'screen-wrapper'} className={'error-wrapper'}>
                    <p>Fehler beim App Start. Bitte laden Sie die Seite neu.</p>
                </div>
            )
        }

        return (
            <div id={'screen-wrapper'}  className={'loading-wrapper'}>
                <Screen toolId={toolId} preview={preview}/>
            </div>
        );
    } else {
        return(<div>Loading Process...</div>);
    }
};

export default Process;