import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import BranchNext from "./BranchNext";
import TermsInput from "../../ui/TermsInput";
import {proceedAction} from "../../../store/actions/Branch";
import toast from "react-hot-toast";

const Branch = () => {
    // console.log('Branch')

    const dispatch = useDispatch();
    const processId = useSelector(state => state.App.processId);
    const submissionId = useSelector(state => state.App.submissionId);
    const module = useSelector(state => state.App.module);
    const branches = module.data.branches;

    const [submitted, updateSubmitted] = useState(false);

    function handleNext (branchId)  {
        updateSubmitted(true);
        if((module.data.module.display_terms_conditions && module.data.module.terms_conditions)) {

            if(document.querySelector('#module-terms-condition-checkbox').checked) {
                dispatch(proceedAction(processId, submissionId, module.data.module.id, branchId, {termsConditions: 1}));
            } else {
                toast.error('Nicht alle erforderlichen Felder ausgefüllt.');
            }

        } else {
            dispatch(proceedAction(processId, submissionId, module.data.module.id, branchId, {}));
        }
    }

    return (
        <div className="module-branch fields-container">
            {
                module.data.module.display_terms_conditions === 1 && module.data.module.terms_conditions.length > 0 &&
                <TermsInput
                    text={module.data.module.terms_conditions}
                    submitted={submitted}
                />
            }
            {
                !!branches.length &&
                <div className={"branches " + (module.data.branch.orientation ? 'horizontal' : 'vertical')}>
                    {branches.map(item => {
                        return(
                            <BranchNext
                                key={item.id.toString()}
                                branch={item}
                                handler={handleNext}
                            />
                        )
                    })}
                </div>
            }
        </div>
    );
}

export default Branch