import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { memo, useMemo, useRef } from 'react';
import { join } from '../../utility/Css';
import { clockIconGetByMoment } from '../../utility/TimeIcons';
import Button from './Button';
import CollapsableButtonContainer from './CollapsableButtonContainer';
import Heading from './Heading';

const getYearGroupProperty = moment => moment.year();
const getWeekGroupProperty = moment => moment.isoWeek();
const momentFromSlot = slot => moment(slot.label, 'DD-MM-YYYY').locale('de');

const groupMoments = (moments, getGroupProperty, transformSubMoments) => {
    const groups = {};

    for (const moment of moments) {
        const property = getGroupProperty(moment);

        if (!groups[property]) {
            groups[property] = [moment];
        }
        else {
            groups[property].push(moment);
        }
    }

    for (const property in groups) {
        groups[property] = transformSubMoments(groups[property]);
    }

    return groups;
}

const structureSlots = slots => {
    const moments = slots.map(momentFromSlot);
    const slotsByMoment = new Map();

    for (let i = 0; i < slots.length; i++)
        slotsByMoment.set(moments[i], slots[i]);
    
    const sortedMoments = moments.sort((a, b) => a - b);

    return groupMoments(sortedMoments, getYearGroupProperty, moments => {
        return groupMoments(moments, getWeekGroupProperty, moments => {
            return moments.map(day => slotsByMoment.get(day));
        });
    });
}

const getWeekHeading = weekMoment => {
    if (weekMoment.isSame(moment(), 'isoWeek')) return 'Diese Woche';
    if (weekMoment.isSame(moment().add(1, 'isoWeek'), 'isoWeek')) return 'Nächste Woche';

    const weekStart = moment(weekMoment).startOf('isoWeek');
    const weekEnd = moment(weekMoment).endOf('isoWeek');
    const isYearChanged = weekStart.year() !== weekEnd.year();
    const isMonthChanged = weekStart.month() !== weekEnd.month();

    const weekStartString = weekStart
        .format('D.' + (isMonthChanged ? 'M.' : '') + (isYearChanged ? 'YYYY' : ''));
    const weekEndString = weekEnd.format('D.M.YYYY');

    return `KW ${weekMoment.isoWeek()}: ${weekStartString} - ${weekEndString}`;
}

const getDayHeading = moment => {
    return moment.format('dddd, DD.MM.YYYY');
}

const renderIcon = moment => {
    const [icon, rotation] = clockIconGetByMoment(moment);

    return (
        <FontAwesomeIcon icon={icon} style={{ transform: `rotate(${rotation})`}} />
    )
}

export default memo(function DateTimeList({ timeSlots, selectedOption, onSelect, className }) {
    const selectedContainerRef = useRef();
    const structuredSlots = useMemo(() => {
        return structureSlots(timeSlots);
    }, [timeSlots]);

    return (
        timeSlots.length > 0 ?
            <div className={join('date-time-list years', className)}>
                {Object.entries(structuredSlots).map(([year, weeks]) => (
                    <div key={year} className="year">
                        <Heading level="3" className="year-heading">{year}</Heading>
                        <div className="weeks">
                            {Object.entries(weeks).map(([week, days]) => (
                                <div key={week} className="week">
                                    <Heading level="4" className="week-heading">
                                        {getWeekHeading(moment().year(year).isoWeek(week))}
                                    </Heading>
                                    <div className="days">
                                        {days.map(slot => (
                                            <div key={slot.label} className="day">
                                                <Heading level="5" className="day-heading">
                                                    {getDayHeading(momentFromSlot(slot))}
                                                </Heading>
                                                <CollapsableButtonContainer
                                                    initialCollapsed={true}
                                                    label="mehr anzeigen"
                                                    showButton={slot.options.length > 8}
                                                >
                                                    <div className="times">
                                                        {slot.options.map(option => (
                                                            <Button
                                                                key={option.value}
                                                                className="time"
                                                                primary
                                                                icon={renderIcon(
                                                                    moment(option.start, 'hh:mm')
                                                                )}
                                                                iconPosition="left"
                                                                onClick={() => {
                                                                    if (!onSelect) return;

                                                                    onSelect?.(option);
                                                                }}
                                                            >
                                                                {option.start} Uhr
                                                            </Button>
                                                        ))}
                                                    </div>
                                                </CollapsableButtonContainer>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                <div ref={selectedContainerRef} className="date-time-list-selected">
                    {selectedOption ? (
                        <>Gewählter Termin: {selectedOption.label}</>
                    ) : (
                        <>Bitte wählen Sie oben einen Termin.</>
                    )}
                </div>
            </div>
            :
            <div>
                <b>Derzeit sind keine Termine verfügbar. Versuchen Sie es später erneut.</b>
            </div>
    );
});