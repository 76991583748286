export const PATTERN_HEX3 = /#([\da-fA-F])([\da-fA-F])([\da-fA-F])/;
export const PATTERN_HEX4 = /#([\da-fA-F])([\da-fA-F])([\da-fA-F])([\da-fA-F])/;
export const PATTERN_HEX6 = /#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/;
export const PATTERN_HEX8 = /#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/;
export const PATTERN_RGBA = /rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*([\d.]*)\)/;
export const PATTERN_RGB = /rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)/;
export const PATTERN_RGB_OR_RGBA = /rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(,\s*([\d.]+))?\)/;

export const isDark = color => {
    return getLuminance(color) < 130;
}

export const getLuminance = color => {
    const { r, g, b } = colorGetValues(color);

    return 0.299 * r + 0.587 * g + 0.114 * b;
}

export const setOpacity = (color, opacity) => {
    const { r, g, b } = colorGetValues(color);

    return intFromValues(r, g, b, opacity);
}

export const toInt = color => {
    if (Number.isInteger(color)) return color;

    const { r, g, b, a } = colorGetValues(color);

    return intFromValues(r, g, b, a);
}

export const toRgb = color => {
    const { r, g, b } = colorGetValues(color);

    return rgbFromValues(r, g, b);
}

export const toRgba = color => {
    const { r, g, b, a } = colorGetValues(color);

    return rgbaFromValues(r, g, b, a);
}

export const toHex = color => {
    const { r, g, b, a } = colorGetValues(color);

    return hexFromValues(r, g, b, a);
}

const intFromValues = (r, g, b, a = 1) => {
    return [r, g, b, Math.round(a * 255)].reduce((acc, component) => acc * (2 ** 8) + component);
}

const rgbFromValues = (r, g, b) => {
    return `rgb(${r}, ${g}, ${b})`;
}

const rgbaFromValues = (r, g, b, a = 1) => {
    return `rgba(${r}, ${g}, ${b}, ${a})`;
}

const hexFromValues = (r, g, b, a) => {
    const components = a === undefined ? [r, g, b] : [r, g, b, a];

    return '#' + components.reduce(
        (acc, component) => acc + component.toString(16).padStart(2, '0')
    );
}

export const colorGetValues = color => {
    if (Number.isInteger(color)) return valuesOfInt(color);
    if (color.startsWith?.('#')) return valuesOfHex(color);
    if (typeof color === 'string') return valuesOfRgba(color);
    
    throw new Error('unknown color format!');
}

const valuesOfInt = color => {
    return {
        r: color >>> 24,
        g: (color >>> 16) & 0xFF,
        b: (color >>> 8) & 0xFF,
        a: (color & 0xFF) / 255,
    }
}

const valuesOfRgba = color => {
    let [_, r, g, b, __, a] = color.match(PATTERN_RGB_OR_RGBA);

    return {
        r: parseInt(r),
        g: parseInt(g),
        b: parseInt(b),
        a: isNaN(a) ? undefined : parseFloat(a)
    };
}

const valuesOfHex = color => {
    if (color.length < 6) {
        return {
            r: parseInt(color[1] + color[1], 16),
            g: parseInt(color[2] + color[2], 16),
            b: parseInt(color[3] + color[3], 16),
            a: color.length < 5 ? undefined : parseInt(color[4] + color[4], 16) / 255
        };
    }

    return {
        r: parseInt(color.substr(1, 2), 16),
        g: parseInt(color.substr(3, 2), 16),
        b: parseInt(color.substr(5, 2), 16),
        a: color.length < 8 ? undefined : parseInt(color.substr(7, 2), 16) / 255
    };
}