import {doDownloadRequest, doServerRequest, doUploadRequest, startLoading, stopLoading} from "./Webservice";
import {toastResponseMessages} from "../../components/ui/Message";
import toast from "react-hot-toast";

export const EVALUATION_ROUTE = 'pc-api-evaluation/';

export const DIAGRAM_UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const DIAGRAM_SET_FILES_COUNT = 'SET_FILES_COUNT';
export const DIAGRAM_UPLOAD_ERROR = 'UPLOAD_ERROR';

export const uploadDiagramm = (processId, submissionId, moduleId, evaluationId, diagramId, type, svgBlob) => {
    return async (dispatch) => {
        startLoading(dispatch);
        const data = {};
        data.processId = processId;
        data.submissionId = submissionId;
        data.moduleId = moduleId;
        data.evaluationId = evaluationId;
        data.diagramId = diagramId;
        data.type = type;
        // let params = new URLSearchParams(data).toString();

        const response = await doUploadRequest(EVALUATION_ROUTE + 'upload-diagram', data, svgBlob);

        if (response) {
            dispatch({
                type: DIAGRAM_UPLOAD_SUCCESS,
                ...response
            });
        } else {
            toast.error('Diagramm konnte übertragen werden.');
            dispatch({
                type: DIAGRAM_UPLOAD_ERROR,
                error: true,
            });
        }

        stopLoading(dispatch, response);
    };
};

export const generateEvaluationAction = (processId, submissionId, moduleId) => {
    return async (dispatch) => {
        startLoading(dispatch);
        const response = await doServerRequest(EVALUATION_ROUTE + 'generate',
            {
                processId: processId,
                submissionId: submissionId,
                moduleId: moduleId,
            });

        if(response && response.status === 'error') {
            toastResponseMessages(response);
        }

        stopLoading(dispatch, response);
    };
};

export const downloadAction = (processId, submissionId, moduleId) => {
    return async (dispatch) => {
        startLoading(dispatch);
        let data = {
            processId: processId,
            submissionId: submissionId,
            moduleId: moduleId
        };

        const response = await doDownloadRequest(EVALUATION_ROUTE + 'download', data);

        if(response && response.status === 'error') {
            toastResponseMessages(response);
        }
        stopLoading(dispatch, response);
    };
};